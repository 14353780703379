import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Text from "../../Text";
import { formatDateAndCategory } from "../utils";

interface Props {
  researchAreas: string[] | null;
  timestamp: string;
}

const BlogDetail: FunctionComponent<Props> = ({ researchAreas, timestamp }) => {
  const blogDetailCopy = formatDateAndCategory(timestamp, researchAreas);
  return (
    <Text
      as={"div"}
      color={"blueMedium"}
      css={css`
        margin-bottom: 1rem;
        text-transform: uppercase;
      `}
      size={"xsmall"}
      weight={"medium"}
    >
      {blogDetailCopy}
    </Text>
  );
};

export default BlogDetail;
