import FACETS from "@10x/algolia-utils/events/facets";

export const EVENT_FACETS = [
  {
    attribute: FACETS.categoryLabel,
    label: "Category",
  },
  {
    attribute: FACETS.regionLabel,
    label: "Location",
  },
  {
    attribute: FACETS.country,
    label: "Country / Region",
  },
  {
    attribute: FACETS.researchAreas,
    label: "Research areas",
  },
  {
    attribute: FACETS.productsName,
    label: "Products",
  },
];
