import type { FunctionComponent } from "react";

import Heading from "../../Heading";

interface Props {
  title: string;
}

const BlogTitle: FunctionComponent<Props> = ({ title = "" }) => {
  return (
    <Heading as={"h2"} responsive={true} size={"xxlarge"} weight={"semibold"}>
      {title}
    </Heading>
  );
};

export default BlogTitle;
