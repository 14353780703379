import type { CompatibleProductsIndexRecord } from "@10x/algolia-utils/compatible-products";
import {
  borderRadiusMedium,
  borderStandard,
  colorWhite,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Text from "../Text";
import Accordion from "./Accordion";
import ProductInfo from "./ProductInfo";

interface Props {
  companyName: string;
  productGroup: CompatibleProductsIndexRecord[];
}

const ProductGroup: FunctionComponent<Props> = ({
  companyName,
  productGroup,
}: Props) => {
  return (
    <>
      <Text
        as={"h3"}
        color={"steelDarker"}
        css={css`
          margin-bottom: 1.25rem;
        `}
        size={"medium"}
        weight={"regular"}
      >
        {companyName}
      </Text>
      <ul
        css={css`
          list-style: none;
          padding: 0;
          background: ${colorWhite};
          border: ${borderStandard};
          border-radius: ${borderRadiusMedium};
          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06);
        `}
      >
        {productGroup.map((product) => {
          return (
            <li
              css={css`
                border-top: ${borderStandard};
                padding: 1.5rem 1rem 1.5rem 2rem;

                :first-of-type {
                  border-top: none;
                }
              `}
              key={product.objectID}
            >
              <Accordion title={product.name}>
                <ProductInfo product={product} />
              </Accordion>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default ProductGroup;
