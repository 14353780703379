import { css } from "@emotion/react";

import CTAButton from "../Masthead/CTAButton";
import type { BackgroundMode, ButtonSize, ButtonType } from "../Masthead/types";
import type { Props as ContainerProps } from "./Layout/Container";
import Container from "./Layout/Container";

type ButtonAlign = "center" | "left";

interface Props {
  align?: ButtonAlign;
  container?: ContainerProps;
  href: string;
  id?: string;
  label: string;
  labelSize?: "small" | "medium";
  mode?: BackgroundMode;
  size?: ButtonSize;
  type?: ButtonType;
}

const Button = ({
  align = "center",
  container,
  mode = "light",
  href = "/",
  id,
  label,
  labelSize = "medium",
  size = { height: "4rem", width: "15rem" },
  type = "primary",
}: Props) => {
  return (
    <Container {...container}>
      <div
        css={css`
          display: flex;
          justify-content: ${align};
        `}
      >
        <CTAButton
          href={href}
          id={id}
          label={label}
          labelSize={labelSize}
          mode={mode}
          size={size}
          target={"_self"}
          type={type}
        />
      </div>
    </Container>
  );
};

export default Button;
