import { css } from "@emotion/react";
import type { Property } from "csstype";
import type { ReactNode } from "react";

import type { WithResponsive } from "../../utils/media-queries";
import { generateResponsiveCSS } from "../../utils/media-queries";

interface FlexItemProps {
  children?: ReactNode;
  className?: string;
  flex?: WithResponsive<Property.Flex>;
  flexGrow?: WithResponsive<Property.FlexGrow>;
  flexShrink?: WithResponsive<Property.FlexShrink>;
}

function FlexItem({ children, className, ...styles }: FlexItemProps) {
  return (
    <div
      className={className}
      css={css`
        ${generateResponsiveCSS({ ...styles })}
      `}
    >
      {children}
    </div>
  );
}

export default FlexItem;
