import StaticPropsContext from "@10x/site/contexts/static-props";
import {
  colorSteelDarker,
  colorSteelDarkest,
  layoutWidth1200,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";
import { useContext } from "react";

import Banner from "../../Blocks/Masthead/Banner";
import FeaturedArticleInfo from "./FeaturedArticleInfo";
import Heading from "./Heading";
import SocialLinks from "./SocialLinks";

interface Props {}

const Masthead: FunctionComponent<Props> = () => {
  const { featuredBlogPost, navigation } = useContext(StaticPropsContext);

  return (
    <div
      className={"Masthead"}
      css={css`
        align-items: center;
        background: ${colorSteelDarkest};
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding: 0 1rem;
        padding-bottom: 4.5rem;
        width: 100%;
      `}
    >
      {navigation ? <Banner mode={"dark"} navigation={navigation} /> : null}

      {featuredBlogPost ? (
        <>
          <div
            className={"Content"}
            css={css`
              align-items: center;
              border-bottom: 1px solid ${colorSteelDarker};
              display: flex;
              justify-content: space-between;
              height: 100%;
              margin-bottom: 4rem;
              max-width: ${layoutWidth1200};
              padding: 3rem 0 1rem;
              width: 100%;
            `}
          >
            <Heading />
            <SocialLinks />
          </div>
          <FeaturedArticleInfo featuredBlogPost={featuredBlogPost} />
        </>
      ) : null}
    </div>
  );
};

export default Masthead;
