import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Icon from "../../Icon";

interface Props {}

const SocialLinks: FunctionComponent<Props> = () => {
  return (
    <div>
      <Anchor
        color={"gray"}
        hoverColor={"white"}
        href={`https://twitter.com/10xGenomics`}
        target={"_blank"}
      >
        <Icon
          color={"inherit"}
          css={{ marginRight: "1.5rem" }}
          size={"32px"}
          source={"twitter"}
          yPos={"1px"}
        />
      </Anchor>
      <Anchor
        color={"gray"}
        hoverColor={"white"}
        href={`https://www.linkedin.com/company/10xgenomics`}
        target={"_blank"}
      >
        <Icon
          color={"inherit"}
          css={{ marginRight: "12px" }}
          size={"32px"}
          source={"linkedin"}
        />
      </Anchor>
    </div>
  );
};

export default SocialLinks;
