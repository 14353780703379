import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import { formatDateAndCategory } from "../Blog/utils";
import Text from "../Text";

interface Props {
  timestamp: string;
}

const GuideDetail: FunctionComponent<Props> = ({ timestamp }) => {
  const blogDetailCopy = formatDateAndCategory(timestamp, []);
  return (
    <Text
      as={"div"}
      color={"blueMedium"}
      css={css`
        text-transform: uppercase;
      `}
      size={"xsmall"}
      weight={"medium"}
    >
      {blogDetailCopy}
    </Text>
  );
};

export default GuideDetail;
