import useMobileDetect from "@10x/hooks/use-mobile-detect";
import { colorWarningBase, sizeXxlarge } from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import Anchor from "../../Anchor";
import Icon from "../../Icon";
import Text from "../../Text";
import { getPrimaryButtonCss, getSecondaryButtonCss } from "./styles";
import type { ButtonType, CTAButton as Props } from "./types";

const CTAButton = ({
  className,
  href,
  icon,
  id,
  label,
  labelSize = "medium",
  labelWeight,
  mobile,
  mode = "dark",
  size = { height: sizeXxlarge, width: "100%" },
  target,
  type = "primary",
}: Props) => {
  const isMobile = useMobileDetect();

  const getButtonStyles = (type: ButtonType) => {
    const styles = {
      ["primary"]: getPrimaryButtonCss(mode, size, mobile),
      ["secondary"]: getSecondaryButtonCss(mode, size),
    };
    return styles[type];
  };

  const typeWeight = type === "primary" ? "semibold" : "medium";

  const weight = labelWeight ? labelWeight : typeWeight;

  return (
    <>
      <Anchor
        className={className}
        css={getButtonStyles(type)}
        href={href}
        id={id}
        target={target}
      >
        {icon ? (
          <Icon
            color={"inherit"}
            css={css`
              flex-shrink: 0;
            `}
            size={"16px"}
            source={icon}
            yPos={"1px"}
          />
        ) : null}
        <Text as={"div"} color={"inherit"} size={labelSize} weight={weight}>
          {label}
        </Text>
      </Anchor>
      {isMobile && mobile?.message ? (
        <div
          css={css`
            color: ${colorWarningBase};
            padding: 0 0.5rem;
          `}
        >
          <Text
            as={"p"}
            color={mode === "dark" ? "inherit" : "blue"}
            css={css`
              display: block;
              margin-bottom: 0.5rem;
              max-width: 304px;
            `}
            size={"xsmall"}
          >
            <Icon
              color={"inherit"}
              css={css`
                margin-right: 0.25rem;
              `}
              size={"10px"}
              source={"warning"}
            />
            {mobile.message}
          </Text>
        </div>
      ) : null}
    </>
  );
};

export default CTAButton;
