import type { ComponentProps } from "react";

import BaseText from "../../Text/Text";
import type { Props as ContainerProps } from "./Layout/Container";
import Container from "./Layout/Container";

interface Props extends Omit<ComponentProps<typeof BaseText>, "children"> {
  container?: ContainerProps;
  content: string;
}

const Text = ({ container, content, ...textProps }: Props) => {
  const {
    as = "div",
    color = "base",
    size = "small",
    weight = "medium",
  } = textProps;

  return (
    <Container {...container}>
      <BaseText
        as={as}
        color={color}
        responsive={true}
        size={size}
        weight={weight}
      >
        {content}
      </BaseText>
    </Container>
  );
};

export default Text;
