import type { BlogPostsIndexRecord } from "@10x/algolia-utils/blog-posts";
import { colorBlueDark } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { MDXRemote } from "next-mdx-remote";
import type { FunctionComponent } from "react";
import type { Hit } from "react-instantsearch-core";
import Truncate from "react-truncate";

import Anchor from "../Anchor";
import MDXComponents from "../Blocks/MDX/MDXComponents";
import Image from "../Image";
import Text from "../Text";
import Author from "./Author";
import { formatDateAndCategory, transformImageSize } from "./utils";

interface Props {
  hit: Hit<BlogPostsIndexRecord>;
}

const BlogCard: FunctionComponent<Props> = ({ hit }) => {
  const { socialImage, researchAreas, timestamp, author, title, slug, mdx } =
    hit;

  const resizedImage = transformImageSize(socialImage, "w_386");
  const blogDetailCopy = formatDateAndCategory(timestamp, researchAreas);
  return (
    <div
      className={"BlogCard"}
      css={css`
        display: flex;
        flex-direction: column;
        min-height: 564px;
      `}
    >
      <Anchor color={"base"} hoverColor={colorBlueDark} href={`/blog/${slug}`}>
        <div
          className={"ImageContainer"}
          css={css`
            height: 192px;
            overflow: hidden;
            margin-left: auto;
            margin-right: auto;
            display: block;
            margin-bottom: 1.5rem;
          `}
        >
          <Image
            alt={title}
            css={css`
              object-fit: cover;
            `}
            src={resizedImage}
          />
        </div>
        <Text
          as={"div"}
          color={"blueMedium"}
          css={css`
            margin-bottom: 1rem;
            max-width: 368px;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: uppercase;
            white-space: nowrap;
          `}
          size={"xsmall"}
          weight={"medium"}
        >
          {blogDetailCopy}
        </Text>
        <Text
          as={"div"}
          color={"inherit"}
          css={css`
            margin-bottom: 1rem;
          `}
          size={"large"}
          weight={"semibold"}
        >
          {title}
        </Text>
      </Anchor>
      <Text
        as={"div"}
        color={"steelDarker"}
        css={css`
          margin-bottom: 1rem;
        `}
        size={"medium"}
        weight={"regular"}
      >
        <Truncate ellipsis={<span>{"..."}</span>} lines={4} width={348}>
          <MDXRemote {...mdx} components={MDXComponents} />
        </Truncate>
      </Text>

      <Author author={author} />
    </div>
  );
};

export default BlogCard;
