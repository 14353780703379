import type {
  Instrument,
  InstrumentWithLink,
  Product,
  ProductWithLink,
} from "./UserGuides/types";

export const getProductsWithLink = (products: Product[]): ProductWithLink[] => {
  return products.map((product) => {
    return {
      ...product,
      link: product.supportLink || `/support/${product.slug}`,
    };
  });
};

export const getInstrumentsWithLink = (
  instruments: Instrument[],
): InstrumentWithLink[] => {
  return instruments.map((instrument) => {
    return {
      ...instrument,
      link: `/support/instruments/${instrument.slug}`,
    };
  });
};

export const scrollToSection = (
  element: HTMLElement | null,
  offset: number = 0,
) => {
  if (element) {
    window.scrollTo({
      behavior: "smooth",
      left: 0,
      top: (element.offsetTop as number) - offset,
    });
  }
};
