import type { WithCompiledMdx } from "@10x/cms/types";
import useScrollMenu from "@10x/hooks/use-scroll-menu";
import type { GetAnalysisGuideQuery } from "@10x/types/__generated__/graphql-types";
import { RefContext } from "@10x/ui/src/SoftwareSupport/LinkableSection/RefContext";
import {
  colorGrayLightest,
  fontFamilyBase,
  fontSizeSmall,
  mediaPhoneOnly,
  mediaTabletLandscape,
  sizeXlarge,
  sizeXxlarge,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { MDXRemoteSerializeResult } from "next-mdx-remote";
import { MDXRemote } from "next-mdx-remote";
import type { FunctionComponent } from "react";
import { useCallback, useRef } from "react";

import Anchor from "../Anchor";
import { MDXComponents } from "../Blocks/MDX";
import Heading from "../Heading";
import Icon from "../Icon";
import Paragraph from "../Paragraph";
import ScrollTopButton from "../ScrollTopButton/ScrollTopButton";
import Callout from "../Support/Callout/Callout";
import OnThisPage from "../Support/OnThisPage";
import OnThisPageItem from "../Support/OnThisPage/Item";
import { scrollToSection } from "../Support/utils";
import WidthWrapper from "../Support/WidthWrapper";
import Text from "../Text";
import GuideCitation from "./GuideCitation";
import GuideDetail from "./GuideDetail";
import Header from "./Header";

type AnalysisGuideWithoutSections = Omit<
  NonNullable<GetAnalysisGuideQuery["analysisGuide"]>,
  "sections"
>;

interface Props {
  data: WithCompiledMdx<AnalysisGuideWithoutSections> & {
    mdx: MDXRemoteSerializeResult;
    sections: {
      linkableTitle: {
        id: string;
        nested: boolean;
        title: string;
      };
    }[];
  };
  isPreview?: boolean;
}

const AnalysisGuide: FunctionComponent<Props> = ({
  data,
  isPreview = false,
}) => {
  const sectionRefs = useRef<{ [key: string]: HTMLButtonElement | null }>({});

  const { activeNavItem } = useScrollMenu(sectionRefs);

  const { mdx, sections, slug, title, updated } = data;

  const handleRef = useCallback(
    (id: string, element: HTMLButtonElement | null) => {
      sectionRefs.current[id] = element;
    },
    [],
  );

  return (
    <>
      <WidthWrapper
        css={css`
          background-color: ${colorGrayLightest};
        `}
        width={"1200px"}
      >
        <Header
          breadCrumbs={[
            {
              label: "Analysis Guides",
              url: "/analysis-guides",
            },
          ]}
          heading={
            <Heading
              as={"h2"}
              color={"base"}
              css={css`
                margin-bottom: 0;
              `}
              size={"xlarge"}
              weight={"semibold"}
            >
              {title}
            </Heading>
          }
        />
      </WidthWrapper>
      <WidthWrapper width={"1200px"}>
        <div
          css={css`
            display: grid;
            gap: 5rem;
            grid-template-columns: minmax(200px, auto) 240px;
            padding: 2rem 0 4rem 0;

            @media (max-width: ${mediaTabletLandscape}) {
              display: flex;
              flex-direction: column-reverse;
              gap: 32px;
              margin: 0;
            }
          `}
        >
          <article
            css={css`
              display: flex;
              flex-direction: column;
              max-width: 880px;
              padding-top: 1rem;
              @media (max-width: ${mediaTabletLandscape}) {
                padding: ${sizeXlarge} 1.5rem;
              }
              @media (max-width: ${mediaPhoneOnly}) {
                padding: 0px;
              }
            `}
          >
            <header
              css={css`
                margin-bottom: ${sizeXxlarge};

                @media (max-width: ${mediaTabletLandscape}) {
                  text-align: left;
                }
              `}
            >
              <div
                css={css`
                  display: flex;
                  justify-content: space-between;
                `}
              >
                <GuideDetail timestamp={updated} />
                <GuideCitation slug={slug} title={title} />
              </div>
              <Text
                as={"h3"}
                color={"steelDarkest"}
                responsive={true}
                size={"xxsmall"}
              >
                {
                  "Note: 10x Genomics does not provide support for community-developed tools and makes no guarantees regarding their function or performance. Please contact tool developers with any questions. If you have feedback about Analysis Guides, please email "
                }
                <Anchor
                  href={"mailto:analysis-guides@10xgenomics.com"}
                  target={"_blank"}
                >
                  {"analysis-guides@10xgenomics.com"}
                </Anchor>
                {"."}
              </Text>
            </header>

            <RefContext.Provider value={{ handleRef }}>
              <MDXRemote {...mdx} components={MDXComponents} />
            </RefContext.Provider>

            <section
              css={css`
                padding: 1rem 0;
              `}
            >
              <Callout level={"announcement"}>
                <div
                  css={css`
                    display: flex;
                    gap: 1rem;
                    flex-wrap: wrap;
                  `}
                >
                  <Text as={"span"} size={"small"} weight={"medium"}>
                    {
                      "Stay connected with latest technical workflow and software updates"
                    }
                  </Text>
                  <Anchor
                    href={"https://pages.10xgenomics.com/subscription.html"}
                    target={"_blank"}
                  >
                    <Text
                      as={"span"}
                      color={"inherit"}
                      size={"small"}
                      weight={"medium"}
                    >
                      {"Subscribe to newsletter"}
                    </Text>
                  </Anchor>
                </div>
              </Callout>
            </section>
          </article>

          <OnThisPage
            css={css`
              height: fit-content;
              position: sticky;
              top: 36px;
              @media (max-width: ${mediaTabletLandscape}) {
                position: static;
              }
            `}
            title={"On This Page"}
          >
            {sections?.map((section) => {
              const element = section.linkableTitle
                ? sectionRefs.current[section.linkableTitle.id]
                : null;
              return section.linkableTitle ? (
                <OnThisPageItem
                  active={element === activeNavItem}
                  key={section.linkableTitle?.id}
                  nested={section.linkableTitle?.nested}
                >
                  <button
                    css={css`
                      background: none;
                      border: none;
                      color: inherit;
                      cursor: pointer;
                      font-family: ${fontFamilyBase};
                      font-size: ${fontSizeSmall};
                      font-weight: inherit;
                      padding: 0;
                      text-align: left;
                    `}
                    onClick={() => {
                      scrollToSection(element, Number.parseInt("16px"));
                    }}
                  >
                    {section.linkableTitle.title}
                  </button>
                </OnThisPageItem>
              ) : null;
            })}
          </OnThisPage>
        </div>
      </WidthWrapper>

      {isPreview ? (
        <footer
          css={css`
            align-items: center;
            display: flex;
          `}
        >
          <Paragraph
            css={css`
              margin-bottom: 0;
              margin-left: 11px;
            `}
            size={"medium"}
            weight={"semibold"}
          >
            <Anchor href={`/analysis-guides/${slug}`}>{"Read more"}</Anchor>
          </Paragraph>

          <Icon
            color={"blue"}
            size={"11px"}
            source={"nav-right"}
            yPos={"2px"}
          />
        </footer>
      ) : null}

      <ScrollTopButton top={10} />
    </>
  );
};

export default AnalysisGuide;
