import type { OptionalToNullable } from "@10x/netlify-cms-graphql/utils/types";

import type { Link } from "./DownloadCard/types";

export const formatFileSize = (fileSizeKb: number): string => {
  if (fileSizeKb > 1000000) {
    return `File size: ${fileSizeKb / 1000000} GB`;
  } else if (fileSizeKb > 1000) {
    return `File size: ${fileSizeKb / 1000} MB`;
  } else {
    return `File size: ${fileSizeKb} KB`;
  }
};

export const getSoftwareDownloadLinks = (
  index: number,
  links: OptionalToNullable<Partial<Link>>[] | null,
) => {
  return index === 0 ? ([links?.[0]] as Link[]) : (links as Link[]);
};
