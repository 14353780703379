import type { LinkItem } from "@10x/cms/types";
import {
  borderRadiusMedium,
  borderStandard,
  colorBlueDark,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../Anchor";
import Heading from "../Heading";
import Icon from "../Icon";
import Image from "../Image";
import Text from "../Text";
import WistiaVideo from "../WistiaVideo";

interface Props {
  border?: boolean;
  description?: string;
  image?: {
    alt?: string;
    url: string;
  };
  linkList?: Array<LinkItem>;
  list?: Array<string>;
  reverse?: boolean;
  title?: string;
  wistiaID?: string;
}

const MediaCard: FunctionComponent<Props> = ({
  border = true,
  description,
  image,
  linkList,
  list,
  reverse,
  title,
  wistiaID,
}) => {
  const columnCss = css`
    margin-right: 0;

    :first-of-type {
      margin-bottom: 2rem;
    }

    @media (min-width: ${mediaTabletLandscape}) {
      :first-of-type {
        flex: ${reverse ? 5 : 4};
        margin-right: ${reverse ? 0 : "10%"};
        order: ${reverse ? 2 : 1};
      }

      :last-of-type {
        display: flex;
        flex: ${reverse ? 4 : 5};
        flex-direction: column;
        margin-right: ${reverse ? "10%" : 0};
        order: ${reverse ? 1 : 2};
      }
    }
  `;

  return (
    <li
      css={css`
        border-top: ${border ? borderStandard : "none"};
        padding: 2rem 0;

        @media (min-width: ${mediaTabletLandscape}) {
          display: flex;
          flex-wrap: nowrap;
          padding: 3rem 0;
        }
      `}
    >
      <div css={columnCss}>
        {title ? (
          <Heading
            as={"h4"}
            css={css`
              margin-bottom: 1.5rem;
            `}
            size={"xlarge"}
          >
            {title}
          </Heading>
        ) : null}

        {description ? (
          <Text
            as={"div"}
            color={"midgray"}
            css={css`
              margin-bottom: 1.5rem;
            `}
            size={"medium"}
          >
            {description}
          </Text>
        ) : null}

        {list?.length ? (
          <ul
            css={css`
              @media (min-width: ${mediaTabletLandscape}) {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                margin: 0 0 1.5rem;
                padding-left: 1rem;
              }
            `}
          >
            {list.map((item) => {
              return (
                <Text
                  as={"li"}
                  color={"midgray"}
                  key={item}
                  responsive={true}
                  size={"medium"}
                >
                  {item}
                </Text>
              );
            })}
          </ul>
        ) : null}

        {linkList ? (
          <ul
            css={css`
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
              list-style: none;
              margin: 0;
              padding: 0;
            `}
          >
            {linkList.map((link) => {
              const { label, type, url } = link;
              return (
                <li key={url}>
                  <Text
                    as={"div"}
                    color={"blue"}
                    size={"small"}
                    weight={"semibold"}
                  >
                    {url ? (
                      <Anchor
                        aria-label={`${label} for ${title}`}
                        color={"inherit"}
                        hoverColor={colorBlueDark}
                        href={url}
                      >
                        {label}
                        {type ? (
                          <Icon
                            size={"12px"}
                            source={type}
                            xPos={"16px"}
                            yPos={"1px"}
                          />
                        ) : null}
                      </Anchor>
                    ) : null}
                  </Text>
                </li>
              );
            })}
          </ul>
        ) : null}
      </div>

      <div css={columnCss}>
        {image ? (
          <div
            css={css`
              height: 100%;
            `}
          >
            <Image
              alt={image.alt}
              css={css`
                border-radius: ${borderRadiusMedium};
              `}
              src={image.url}
            />
          </div>
        ) : null}

        {wistiaID ? (
          <div>
            <WistiaVideo wistiaID={wistiaID} />
          </div>
        ) : null}
      </div>
    </li>
  );
};

export default MediaCard;
