export const ACHIEVEMENTS = [
  "instruments",
  "patents",
  "publication-count",
  "innovation-awards",
];

export const BOARD = [
  "kim-popovits",
  "shehnaaz-suliman",
  "sri-kosaraju",
  "alan-mateo",
  "mathai-mammen",
  "sarah-teichmann",
  "john-stuelpnagel",
  "ben-hindson",
  "serge-saxonov",
];

export const EXECUTIVES = [
  "ben-hindson",
  "justin-mcanear",
  "rebecca-port",
  "serge-saxonov",
  "michael-schnall-levin",
  "eric-whitaker",
  "alexander-wong",
  "mennah-moustafa",
];

export const LEADERSHIP = [
  { label: "Executive Team", slug: "team" },
  { label: "Board of Directors", slug: "board" },
];

export const REGIONS = ["amr", "emea", "apac"];

export const REGION_LABELS: Record<string, string> = {
  ["amr"]: "US",
  ["apac"]: "Asia",
  ["emea"]: "EU",
};
