import type { Person } from "@10x/cms/model/person";
import type { ResolvedCMSEntry, WithCompiledMdx } from "@10x/cms/types";
import {
  mediaTabletLandscape,
  mediaTabletPortrait,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { MDXRemote } from "next-mdx-remote";
import type { FunctionComponent } from "react";

import MDXComponents from "../Blocks/MDX/MDXComponents";
import Heading from "../Heading";
import Text from "../Text";
import NavList from "./NavList";
import NavTabs from "./NavTabs";

interface Props {
  person: Person & WithCompiledMdx & ResolvedCMSEntry;
  sidebar: any;
}

const Profile: FunctionComponent<Props> = ({ person, sidebar }) => {
  const fullName = `${person?.foreName} ${person?.lastName}`;

  return (
    <>
      {sidebar?.sections.length ? (
        <NavTabs active={sidebar?.type} sections={sidebar?.sections} />
      ) : null}

      <div
        css={css`
          margin: 0 auto;
          max-width: 1200px;
          padding-top: 3.5rem;

          @media (min-width: ${mediaTabletLandscape}) {
            display: flex;
          }
        `}
      >
        <div
          css={css`
            @media (min-width: ${mediaTabletPortrait}) {
              flex: 3;
            }

            @media (min-width: ${mediaTabletLandscape}) {
              flex: 2;
              padding-right: 10%;
            }
          `}
        >
          {sidebar?.list.length ? (
            <NavList
              currentSlug={person.slug}
              list={sidebar?.list}
              type={sidebar?.type}
            />
          ) : null}
        </div>

        {person ? (
          <div
            css={css`
              @media (min-width: ${mediaTabletPortrait}) {
                display: flex;
              }

              @media (min-width: ${mediaTabletLandscape}) {
                flex: 7;
              }
            `}
          >
            {person?.avatar ? (
              <div
                css={css`
                  margin-right: 4rem;
                  max-width: 300px;
                `}
              >
                <img
                  alt={fullName}
                  css={css`
                    border-radius: 6px;
                    max-width: 100%;
                  `}
                  src={person.avatar}
                />
              </div>
            ) : null}

            <div
              css={css`
                @media (min-width: ${mediaTabletPortrait}) {
                  flex: 4;
                }
              `}
            >
              {person?.lastName ? (
                <>
                  <Heading
                    as={"h3"}
                    css={css`
                      margin-bottom: 0.25rem;
                    `}
                    size={"xxlarge"}
                  >
                    {fullName}
                  </Heading>

                  <Text
                    as={"div"}
                    css={css`
                      margin-bottom: 2.5rem;
                    `}
                    size={"medium"}
                    weight={"medium"}
                  >
                    {sidebar.type === "team" ? person?.position : null}
                  </Text>

                  {person?.mdx ? (
                    <MDXRemote {...person?.mdx} components={MDXComponents} />
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Profile;
