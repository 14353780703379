import AnalysisGuideSearch from "../../AnalysisGuide/Search";
import Anchor from "../../Anchor";
import Blockquote from "../../Blockquote";
import { BlogMasthead, BlogSearch } from "../../Blog";
import Button from "../../Button/Button";
import { Callout } from "../../Callout";
import RowCard from "../../Cards/RowCard";
import CareerBenefitsList from "../../CareerBenefitsList";
import Code from "../../Code";
import Achievements from "../../Company/Achievements";
import Board from "../../Company/Board";
import Executives from "../../Company/Executives";
import Locations from "../../Company/Locations";
import Profile from "../../Company/Profile";
import CompatibleProductSearch from "../../CompatibleProducts";
import Distributors from "../../Distributors";
import DocumentSearch from "../../DocumentSearch";
import EventSearch from "../../Events";
import Figure from "../../Figure";
import Figures from "../../Figures";
import Flex from "../../Flex";
import FlexItem from "../../Flex/FlexItem";
import Grid from "../../Grid";
import GridItem from "../../Grid/GridItem";
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
} from "../../Heading";
import Icon from "../../Icon";
import Check from "../../Icon/Check";
import Wrong from "../../Icon/Wrong";
import Iframe from "../../Iframe";
import Image from "../../Image";
import ListItem from "../../ListItem";
import News from "../../News";
import Paragraph from "../../Paragraph";
import PreformattedText from "../../PreformattedText";
import ProductCompare from "../../ProductCompare";
import ServiceProviderSearch from "../../ServiceProviders";
import SiteSearch from "../../SiteSearch";
import DownloadLinkList from "../../SoftwareDownload/DownloadCard/DownloadLinkList";
import NavLink from "../../SoftwareDownload/DownloadCard/NavLink";
import LinkableSection from "../../SoftwareSupport/LinkableSection";
import LinkableTitle from "../../SoftwareSupport/LinkableTitle";
import SlideSerialDownload from "../../SoftwareSupport/SlideSerialDownload";
import SoftwareToggle from "../../SoftwareSupport/SoftwareToggle";
import SoftwareWorkflow from "../../SoftwareSupport/SoftwareWorkflow";
import DownloadCard from "../../SoftwareSupport/SupportSoftwareDownload/DownloadCard";
import DownloadLinks from "../../SoftwareSupport/SupportSoftwareDownload/DownloadLinks";
import SupportCallout from "../../Support/Callout/Callout";
import DocumentCallout from "../../Support/Document/Callout";
import LinkCard from "../../Support/LinkCard";
import WorkflowCard from "../../Support/WorkflowCard";
import Table, {
  TableBody,
  TableDataCell,
  TableHead,
  TableHeaderCell,
  TableRow,
} from "../../Table";
import Text from "../../Text";
import ThematicBreak from "../../ThematicBreak";
import UnorderedList from "../../UnorderedList";
import VideoCarousel from "../../VideoCarousel";
import VideoSearch from "../../Videos";
import WistiaVideo from "../../WistiaVideo";
import Section from "../../XeniumDatasetExplorer/Section";
import CTAButton from "../Masthead/CTAButton";
import SectionInfo from "../SectionInfo";

/**
 * Exports the subset of components required by MDX.
 */
const MDXScope = {
  Achievements,
  AnalysisGuideSearch,
  Anchor,
  Blockquote,
  BlogMasthead,
  BlogSearch,
  Board,
  Button,
  CTAButton,
  Callout,
  CareerBenefitsList,
  Check,
  Code,
  CompatibleProductSearch,
  Distributors,
  DocumentCallout,
  DocumentSearch,
  DownloadCard,
  DownloadLinkList,
  DownloadLinks,
  EventSearch,
  Executives,
  Figure,
  Figures,
  Flex,
  FlexItem,
  Grid,
  GridItem,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  Icon,
  Iframe,
  Image,
  LinkCard,
  LinkableSection,
  LinkableTitle,
  ListItem,
  Locations,
  NavLink,
  News,
  Paragraph,
  PreformattedText,
  ProductCompare,
  Profile,
  RowCard,
  Section,
  SectionInfo,
  ServiceProviderSearch,
  SiteSearch,
  SlideSerialDownload,
  SoftwareToggle,
  SoftwareWorkflow,
  SupportCallout,
  Table,
  TableBody,
  TableDataCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  Text,
  ThematicBreak,
  UnorderedList,
  VideoCarousel,
  VideoSearch,
  WistiaVideo,
  WorkflowCard,
  Wrong,
};

export default MDXScope;
