import { colorSteelDarkest } from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import Anchor from "../../Anchor";
import Icon from "../../Icon";
import Text from "../../Text";

const BlogBackButton = () => {
  return (
    <Anchor
      color={"gray"}
      css={css`
        align-items: center;
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        margin-bottom: 2rem;
        width: 100%;
      `}
      hoverColor={colorSteelDarkest}
      href={"/blog"}
    >
      <Icon color={"inherit"} size={"11px"} source={"nav-left"} yPos={"1px"} />

      <Text
        as={"span"}
        color={"inherit"}
        css={css`
          margin-bottom: 0;
          margin-left: 11px;
        `}
        size={"large"}
        weight={"medium"}
      >
        {"Blog"}
      </Text>
    </Anchor>
  );
};

export default BlogBackButton;
