import type {
  Step,
  SupportDocument,
} from "@10x/algolia-utils/support-documents";
import type { Hit } from "react-instantsearch-core";

import { getCompatibilityTableHref } from "../../DocumentsPage/utils";
import { getStepHref } from "../../PageLayout/utils";

export const getHref = (step: Step, hit: Hit<SupportDocument>) => {
  if (hit.isCompatibilityTable) {
    return getCompatibilityTableHref({
      documentSlug: hit.slug,
      step: step,
      supportProductPageSlug: step.productSlug,
    });
  } else if (step.slug === "instruments" && hit.instruments.length > 0) {
    return [
      "/support",
      step.productSlug,
      "documentation",
      "instruments",
      hit.instruments[0].slug,
      hit.slug,
    ].join("/");
  } else {
    return [
      getStepHref({
        step: step,
        supportProductPage: { slug: step.productSlug },
        workflow: step.workflowSlug ? { slug: step.workflowSlug } : null,
      }),
      hit.slug,
    ].join("/");
  }
};
