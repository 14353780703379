import { blogPostsIndexName } from "@10x/algolia-utils/index-names";
import { colorWhite } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import BlogLayout from "./BlogLayout";
import Search from "./CustomSearch";

interface Props {}

const BlogSearch: FunctionComponent<Props> = () => {
  return (
    <>
      <div
        css={css`
          background: ${colorWhite};
          min-height: 880px;
          overflow-x: hidden;
        `}
      >
        <Search
          hitsRenderer={({ onHits, searchState }) => {
            return <BlogLayout onHits={onHits} searchState={searchState} />;
          }}
          includeStats={false}
          indexName={blogPostsIndexName.toString()}
        />
      </div>
    </>
  );
};

export default BlogSearch;
