import {
  colorGrayLightest,
  colorSteelDarker,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent, ReactNode } from "react";
import { Fragment } from "react";

import Anchor from "../Anchor";
import Heading from "../Heading";
import Text from "../Text";

export interface BreadCrumb {
  label: string;
  url: string;
}
interface CoreProps {
  breadCrumbs: BreadCrumb[];
  className?: string;
  headerContent?: ReactNode;
}

interface WithHeadingProps extends CoreProps {
  heading: ReactNode;
  headline?: undefined;
}

interface WithHeadlineProps extends CoreProps {
  heading?: undefined;
  headline: string;
}

type Props = WithHeadlineProps | WithHeadingProps;

const Header: FunctionComponent<Props> = ({
  breadCrumbs,
  className,
  heading,
  headline,
  headerContent,
}) => {
  return (
    <div
      className={className}
      css={css`
        background-color: ${colorGrayLightest};
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 1.5rem;
        padding-bottom: 2rem;
        width: 100%;

        @media (max-width: ${mediaTabletLandscape}) {
          padding-top: 16px;
          padding-bottom: 16px;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 0.5rem;
        `}
      >
        {breadCrumbs.map(({ label, url }: BreadCrumb) => {
          return (
            <Fragment key={label}>
              <Anchor color={"gray"} hoverColor={colorSteelDarker} href={url}>
                <Text
                  as={"span"}
                  color={"inherit"}
                  size={"small"}
                  weight={"regular"}
                >
                  {label}
                </Text>
              </Anchor>
              <Text
                as={"span"}
                color={"gray"}
                css={{ margin: "0 .25rem" }}
                size={"small"}
                weight={"regular"}
              >
                {"/"}
              </Text>
            </Fragment>
          );
        })}
      </div>
      {heading || (
        <Heading
          as={"h2"}
          color={"base"}
          css={{ marginBottom: "1rem" }}
          size={"xxlarge"}
          weight={"semibold"}
        >
          {headline}
        </Heading>
      )}
      {headerContent}
    </div>
  );
};

export default Header;
