import { css } from "@emotion/react";
import { v4 as uuidv4 } from "uuid";

import type { TextColor, TextSize } from "../../../../types";
import Anchor from "../../Anchor/Anchor";
import Icon from "../../Icon/Icon";
import type { IconSource } from "../../Icon/types";
import Text from "../../Text/Text";
import type { Props as ContainerProps } from "./Layout/Container";
import Container from "./Layout/Container";

interface Props {
  container?: ContainerProps;
  gap?: string;
  links: {
    color?: TextColor;
    hoverColor?: TextColor;
    icon: IconSource;
    label: string;
    url: string;
  }[];
  size?: TextSize;
}

const Links = ({ container, links, size = "medium" }: Props) => {
  return (
    <Container
      css={css`
        list-style: none;
        margin: 0;
        padding: ${container?.padding || 0};
      `}
      {...container}
    >
      {links.map((link) => {
        return (
          <Anchor
            color={link.color}
            css={css`
              align-items: center;
              display: flex;
              gap: 0.75rem;
            `}
            hoverColor={link.hoverColor}
            href={link.url}
            key={uuidv4()}
          >
            <Text
              as={"div"}
              color={"inherit"}
              responsive={true}
              size={size}
              weight={"semibold"}
            >
              {link.label}
            </Text>
            <Icon
              color={"inherit"}
              size={"12px"}
              source={link.icon}
              yPos={"1px"}
            />
          </Anchor>
        );
      })}
    </Container>
  );
};

export default Links;
