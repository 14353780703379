import { analysisGuidesIndexName } from "@10x/algolia-utils/index-names";
import { colorSteelDarker, fontSizeXsmall } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { format } from "date-fns";

import Anchor from "../../Anchor";
import Paragraph from "../../Paragraph";
import Search from "../../Search";
import Filters from "../../Search/Filters";
import { ANALYSIS_GUIDES_FACETS } from "../constants";
import GuideCard from "../GuideCard";
import List from "./List";

const AnalysisGuideSearch = () => {
  return (
    <div
      css={css`
        min-height: 880px;
      `}
    >
      <Paragraph
        css={css`
          color: ${colorSteelDarker};
          font-size: ${fontSizeXsmall};
          margin-bottom: 1rem;
        `}
        size={"medium"}
      >
        {
          "10x Genomics does not provide support for community-developed tools and makes no guarantees regarding their function or performance. Please contact tool developers with any questions. If you have feedback about Analysis Guides, please email "
        }
        <Anchor href={"mailto:analysis-guides@10xgenomics.com"}>
          {"analysis-guides@10xgenomics.com"}
        </Anchor>
        {". "}
        {"To learn about tools developed by 10x Genomics, see the "}
        <Anchor href={"/support"} target={"_blank"}>
          {"10x Genomics support site"}
        </Anchor>
        {"."}
      </Paragraph>

      <Search
        hitsRenderer={({ onHits }) => {
          return (
            <List
              hitRenderer={({ hit }) => (
                <GuideCard
                  {...hit}
                  topic={hit.topics?.[0]}
                  updated={format(new Date(hit.updated), "MMM dd, yyyy")}
                />
              )}
              onHits={onHits}
            />
          );
        }}
        includeStats={true}
        indexName={analysisGuidesIndexName.toString()}
        placeholder={"Search analysis types, topics, and more"}
        sidebar={<Filters facets={ANALYSIS_GUIDES_FACETS} />}
      />
    </div>
  );
};

export default AnalysisGuideSearch;
