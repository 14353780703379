import useMutationObserver from "@rooks/use-mutation-observer";
import type { ReactNode } from "react";
import { useCallback, useEffect, useRef, useState } from "react";

export const useAccordion = ({
  activeInitial = false,
  children,
}: {
  activeInitial: boolean;
  children: ReactNode;
}) => {
  const [active, setActive] = useState<boolean | undefined>(undefined);
  const contentRef = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState(() => (activeInitial ? "auto" : "0px"));

  if (
    activeInitial &&
    typeof active === "undefined" &&
    active !== activeInitial
  ) {
    setActive(activeInitial);
  }

  const reflow = useCallback(() => {
    const contentHeight = contentRef.current?.scrollHeight;
    setHeight(active ? `${contentHeight}px` : "0px");
  }, [active]);

  useEffect(() => {
    reflow();
  }, [active, children, reflow]);

  useMutationObserver(contentRef, reflow);

  const toggleAccordion = () => {
    setActive((prevActive) => !prevActive);
  };

  return { active, children, contentRef, height, toggleAccordion };
};

export default useAccordion;
