import { mediaTabletLandscape } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { ReactNode } from "react";

interface Props {
  children: ReactNode;
  className?: string;
  width?: string;
}

export default function WidthWrapper({
  children,
  className,
  width = "1080px",
}: Props) {
  return (
    <section
      className={className}
      css={css`
        @media (max-width: ${mediaTabletLandscape}) {
          padding-left: 24px;
          padding-right: 24px;
          width: 100vw;
        }
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        padding-left: 48px;
        padding-right: 48px;
        width: 100%;
      `}
    >
      <div
        css={css`
          max-width: ${width};
          width: 100%;
        `}
      >
        {children}
      </div>
    </section>
  );
}
