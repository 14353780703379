import type { GetAllEventsQuery } from "@10x/types/__generated__/graphql-types";
import { mediaTabletLandscape } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Text from "../Text";
import ThematicBreak from "../ThematicBreak";
import Details from "./Details";
import { getEventColor, getEventIcon } from "./utils";
import { getEventDate } from "./utils";

interface Props {
  event: NonNullable<GetAllEventsQuery["events"]>[0];
}

/**
 * A list of event details, showing online status,
 * location, and times.
 */
const EventCardContentMobile: FunctionComponent<Props> = ({ event }) => {
  const { category, end, name, start } = event;

  const color = category && getEventColor(category);
  const EventCategoryIcon = category && getEventIcon(category);

  return (
    <div
      css={css`
        border-left: 6px solid ${color};
        box-sizing: border-box;
        cursor: pointer;
        display: none;
        flex-direction: column;
        overflow: hidden;
        padding: 24px 32px;
        width: 100%;

        @media (max-width: ${mediaTabletLandscape}) {
          display: flex;
        }
      `}
    >
      <Text
        as={"h1"}
        css={css`
          margin-bottom: 16px;
        `}
        size={"small"}
        weight={"semibold"}
      >
        {getEventDate(start, end)}
      </Text>

      <Text
        as={"h2"}
        css={css`
          margin-bottom: 24px;
        `}
        size={"medium"}
        weight={"semibold"}
      >
        {name}
      </Text>

      <ThematicBreak
        css={css`
          margin: 0 0 16px 0;
        `}
      />

      {color && (
        <div
          css={css`
            align-items: center;
            display: flex;
            gap: 12px;
          `}
        >
          {EventCategoryIcon ? <EventCategoryIcon /> : null}
          <Text
            as={"h3"}
            color={"inherit"}
            css={css`
              color: ${color};
            `}
            size={"small"}
            weight={"medium"}
          >
            {category?.label || ""}
          </Text>
        </div>
      )}

      <Details event={event} hideHeader={true} />
    </div>
  );
};

export default EventCardContentMobile;
