import type { GetSupportProductPageQuery } from "@10x/types/__generated__/graphql-types";
import { compact } from "lodash-es";

import type { IconSource } from "../../../Icon/types";
import type { Props as SideNavProps } from "../../SideNav";
import type { Props as ExpandableSideNavProps } from "../../SideNav/AccordionSideNav";

interface Step {
  href?: string | null;
  shortTitle?: string | null;
  slug?: string | null;
  target?: string | null;
  title: string;
}

interface Workflow {
  slug: string;
  steps?: Step[] | null;
}

interface SupportProductPage {
  slug: string;
}

interface Workflow {
  shortTitle?: string | null;
  title?: string | null;
}

const createMenuItems = ({
  supportProductPage,
  workflow,
  steps,
}: {
  steps: Step[] | null;
  supportProductPage: SupportProductPage;
  workflow: Workflow;
}) => {
  return steps?.map((step) =>
    extractAttributesFromRawStep({
      step,
      supportProductPage,
      workflow,
    }),
  );
};

const createExpandableSubNav = (
  supportProductPage: SupportProductPage,
  workflows: Workflow[],
) => {
  return workflows.map((workflow) => {
    const menus = [
      {
        items: createMenuItems({
          steps: workflow.steps!,
          supportProductPage,
          workflow,
        }),
        title: workflow.shortTitle || workflow.title,
      },
    ];

    return {
      menus,
      title: workflow.shortTitle || workflow.title,
    };
  });
};

const extractAttributesFromRawStep = ({
  supportProductPage,
  workflow,
  step,
}: {
  step: Step;
  supportProductPage: SupportProductPage;
  workflow?: Workflow;
}) => {
  const link =
    step.href ||
    getStepHref({
      step: step as {
        slug: string;
      },
      supportProductPage,
      workflow,
    });

  return {
    link,
    target: step.target,
    title: step.shortTitle || step.title,
  };
};

const createSubNav = (
  supportProductPage: SupportProductPage,
  steps: Step[],
) => {
  return {
    children: steps.map((step) =>
      extractAttributesFromRawStep({
        step,
        supportProductPage,
      }),
    ),
  };
};

export const getSideNavProps = (
  supportProductPage: SupportProductPage,
  supportCmsSideNavData: NonNullable<
    GetSupportProductPageQuery["supportProductPage"]
  >["sideNav"],
  documentation: {
    steps?: Step[] | null;
    workflows?: Workflow[] | null;
  },
): SideNavProps | ExpandableSideNavProps => {
  const navItems = supportCmsSideNavData.navItems.map((navItem) => {
    const { title, icon, link, target } = navItem;

    if (title !== "Documentation") {
      return { icon: icon as IconSource, link, target, title };
    }

    if (documentation.workflows?.length) {
      return {
        icon: icon as IconSource,
        link,
        subNav: createExpandableSubNav(
          supportProductPage,
          documentation.workflows,
        ),
        target,
        title,
      };
    }

    return {
      icon: icon as IconSource,
      link,
      target,
      title,
      ...(documentation.steps
        ? createSubNav(supportProductPage, documentation.steps)
        : {}),
    };
  });

  return {
    link: supportCmsSideNavData.link || "",
    navItems: navItems,
    title: supportCmsSideNavData.title,
  };
};

type HrefSegment = { slug: string };

export function getStepHref({
  step,
  supportProductPage,
  workflow,
}: {
  step: HrefSegment;
  supportProductPage: HrefSegment;
  workflow?: HrefSegment | null;
}) {
  return compact(
    [
      `/support`,
      supportProductPage.slug,
      `documentation`,
      workflow && ["workflows", workflow.slug],
      step.slug === "instruments" ? null : ["steps"],
      step.slug,
    ].flat(),
  ).join("/");
}
