import type { GetAllEventsQuery } from "@10x/types/__generated__/graphql-types";
import { colorSteelDarkest, colorSteelMedium } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Icon from "../Icon";
import type { IconSource } from "../Icon/types";
import Text from "../Text";
import ThematicBreak from "../ThematicBreak";
import { getEventDate, getEventLocation, getEventTimes } from "./utils";

interface Props {
  collapsed?: boolean;
  event: NonNullable<GetAllEventsQuery["events"]>[0];
  hideHeader?: boolean;
}

type Detail = [IconSource, string, string];

/**
 * A list of event details, showing online status,
 * location, and times.
 */
const Details: FunctionComponent<Props> = ({
  collapsed,
  event,
  hideHeader,
}) => {
  const { end, isOnline, start, timezone } = event;

  const isOnlineDetail: Detail | null = isOnline
    ? ["video", "Online event", "6px"]
    : null;

  const location = getEventLocation(event);
  const locationDetail: Detail | null = location
    ? ["pin", location, "5px"]
    : null;

  const timeDetails: Detail = [
    "clock",
    getEventTimes(start, end, timezone),
    "4px",
  ];

  const details = [isOnlineDetail, locationDetail, timeDetails].filter(
    (detail) => detail,
  ) as Detail[];

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: ${collapsed ? "226px" : "240px"};
      `}
    >
      {hideHeader ? null : (
        <>
          <Text
            as={"h3"}
            css={css`
              margin-bottom: 16px;
            `}
            size={"medium"}
            weight={"semibold"}
          >
            {getEventDate(start, end)}
          </Text>

          <ThematicBreak
            css={css`
              margin: 0 0 16px 0;
            `}
          />
        </>
      )}

      <ul
        css={css`
          display: flex;
          flex-direction: column;
          gap: 16px;
          list-style: none;
          margin: ${hideHeader ? "12px 0 0 0" : "0 0 0 8px"};
          padding: 0;
        `}
      >
        {details.map((detail) => {
          const [icon, label, iconOffsetY] = detail;

          return (
            <li
              css={css`
                align-items: flex-start;
                display: flex;
                gap: 16px;
              `}
              key={`${icon}-${label}`}
            >
              <Icon
                css={{
                  color: colorSteelMedium,
                  overflow: "visible",
                }}
                size={"16px"}
                source={icon}
                yPos={iconOffsetY}
              />

              <Text
                as={"span"}
                css={css`
                  color: ${colorSteelDarkest};
                `}
                size={"small"}
                weight={"medium"}
              >
                {label}
              </Text>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Details;
