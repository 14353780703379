import type { BlogPostsIndexRecord } from "@10x/algolia-utils/blog-posts";
import { mediaPhoneOnly } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Icon from "../../Icon";
import Text from "../../Text";
import Author from "../Author";

interface Props extends Pick<BlogPostsIndexRecord, "author"> {
  slug: string;
  title: string;
}

const BlogCitation: FunctionComponent<Props> = ({ author, slug, title }) => {
  const socialPostUrl = `https://10xgenomics.com/blog/${slug}`;
  return (
    <div
      className={"BlogCitation"}
      css={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
      `}
    >
      <Author author={author} />
      <div
        className={"ShareLinks"}
        css={css`
          display: flex;
        `}
      >
        <div
          className={"ShareCopy"}
          css={css`
            @media (max-width: ${mediaPhoneOnly}) {
              display: none;
            }
          `}
        >
          <Text
            as={"div"}
            color={"gray"}
            css={css`
              margin-right: 1.25rem;
            `}
            size={"small"}
            weight={"regular"}
          >
            {"Share via:"}
          </Text>
        </div>
        <Anchor
          color={"gray"}
          css={css`
            margin-right: 0.5rem;
          `}
          hoverColor={"midgray"}
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${socialPostUrl}&title=${title}`}
          target={"_blank"}
        >
          <Icon
            color={"inherit"}
            css={css`
              margin-right: 0.75rem;
            `}
            size={"24px"}
            source={"linkedin"}
          />
        </Anchor>
        <Anchor
          color={"gray"}
          hoverColor={"midgray"}
          href={`https://twitter.com/intent/tweet?text=${title}&url=${socialPostUrl}`}
          target={"_blank"}
        >
          <Icon
            color={"inherit"}
            css={css`
              margin-right: 2.25rem;
            `}
            size={"24px"}
            source={"twitter"}
            yPos={"1px"}
          />
        </Anchor>
      </div>
    </div>
  );
};

export default BlogCitation;
