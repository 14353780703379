import { colorGreyscale94 } from "@10xdev/design-tokens";
import {
  borderRadiusLarge,
  borderRadiusMedium,
  borderRadiusSmall,
  borderStandard,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import Iframe from "../../Iframe";
import type { MarkerContent } from "./BulletedList";
import BulletedList from "./BulletedList";
import Button from "./Button";
import Carousel from "./Carousel";
import Gif from "./Gif";
import Icon from "./Icon";
import Image from "./Image";
import Flex from "./Layout/Flex";
import Grid from "./Layout/Grid";
import Stack from "./Layout/Stack";
import Links from "./Links";
import Mdx from "./MDX";
import OrderedList from "./OrderedList";
import Paragraphs from "./Paragraphs";
import Text from "./Text";
import Title from "./Title";
import type { StyleProps } from "./types";
import Video from "./Video";
import Videos from "./Videos";

export const getContent = (item: Record<string, any>) => {
  switch (item.type) {
    case "sectionTitle":
      return (
        <Title
          container={item.container}
          eyebrow={item.eyebrow}
          headline={item.headline}
        />
      );
    case "sectionText":
      return (
        <Text
          as={item.as}
          color={item.color}
          container={item.container}
          content={item.content}
          size={item.size}
          weight={item.weight}
        />
      );
    case "sectionParagraphs":
      return (
        <Paragraphs
          color={item.color}
          container={item.container}
          paragraphs={item.paragraphs}
          size={item.size}
        />
      );
    case "sectionImage":
      return (
        <Image
          alt={item.alt}
          container={item.container}
          link={item.link}
          src={item.src}
          srcSmall={item.srcSmall}
        />
      );
    case "sectionIcon":
      return (
        <Icon
          color={item.color}
          container={item.container}
          size={item.size}
          source={item.source}
          xPos={item.xPos}
          yPos={item.yPos}
        />
      );
    case "sectionButton":
      return (
        <Button
          align={item.align}
          container={item.container}
          href={item.href}
          id={item.id}
          label={item.label}
          labelSize={item.labelSize}
          mode={item.mode}
          size={item.size}
          type={item.buttonType}
        />
      );
    case "sectionBulletedList":
      return (
        <BulletedList
          color={item.color}
          container={item.container}
          list={item.list}
          marker={item.marker}
          size={item.size}
        />
      );
    case "sectionOrderedList":
      return (
        <OrderedList
          color={item.color}
          container={item.container}
          list={item.list}
          size={item.size}
        />
      );
    case "sectionGif":
      return <Gif container={item.container} src={item.src} />;
    case "sectionLinks":
      return (
        <Links container={item.container} links={item.links} size={item.size} />
      );
    case "sectionVideos":
      return <Videos container={item.container} videos={item.videos} />;
    case "sectionGrid":
      return (
        <Grid
          cellWidth={item.cellWidth}
          container={item.container}
          gap={item.gap}
          items={item.items}
        />
      );
    case "sectionCarousel":
      return (
        <Carousel
          aspectRatio={item.aspectRatio}
          container={item.container}
          gap={item.gap}
          itemHeight={item.itemHeight}
          items={item.items}
          itemsPerScroll={item.itemsPerScroll}
        />
      );
    case "sectionIframe":
      return (
        <div
          css={css`
            width: ${item.container.width};
            @media (max-width: 900px) {
              width: 100%;
            }
          `}
        >
          <Iframe
            css={css`
              border: solid 1px ${colorGreyscale94};
              border-radius: 6px;
            `}
            height={item.height}
            source={item.url}
            title={item.title}
          />
        </div>
      );
    case "sectionVideo":
      return (
        <Video
          container={item.container}
          src={item.src}
          wistiaID={item.wistiaID}
        />
      );
    case "sectionStack":
      return <Stack container={item.container} content={item.content} />;
    case "sectionFlex":
      return (
        <Flex
          content={item.content}
          layout={item.layout}
          styles={item.styles}
        />
      );
    case "sectionMdx":
      return <Mdx container={item.container} content={item.content} />;
    default:
      return null;
  }
};

export const getBulletMarker = (marker: MarkerContent) => {
  const markers = {
    bullet: `"•"`,
    check: `url("data:image/svg+xml;charset=UTF-8,%3csvg width='17' height='12' viewBox='0 0 17 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.8411 1.2364C16.0754 1.47071 16.0754 1.85061 15.8411 2.08492L6.80561 11.1204C6.57129 11.3547 6.19139 11.3547 5.95708 11.1204L0.980423 6.14375C0.746109 5.90943 0.746109 5.52953 0.980423 5.29522L2.04108 4.23456C2.2754 4.00025 2.6553 4.00025 2.88961 4.23456L6.38134 7.72629L13.9319 0.175736C14.1662 -0.0585786 14.5461 -0.0585787 14.7804 0.175736L15.8411 1.2364Z' fill='%2300B398'/%3e%3c/svg%3e ")`,
    checkBlue: `url(https://cdn.10xgenomics.com/image/upload/v1711924936/check-blue.svg)`,
    circledCheck: `url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="24" height="24" rx="12" fill="%23E4EFFB"/><path fill-rule="evenodd" clip-rule="evenodd" d="M19.8362 7.2364C20.0705 7.47071 20.0705 7.85061 19.8362 8.08492L10.8007 17.1204C10.5664 17.3547 10.1865 17.3547 9.95219 17.1204L4.97554 12.1437C4.74123 11.9094 4.74123 11.5295 4.97554 11.2952L6.0362 10.2346C6.27052 10.0002 6.65042 10.0002 6.88473 10.2346L10.3765 13.7263L17.927 6.17574C18.1613 5.94142 18.5412 5.94142 18.7755 6.17574L19.8362 7.2364Z" fill="%230071D9"/></svg>')`,
    none: "",
    warningYellow: `url(https://cdn.10xgenomics.com/image/upload/v1711925294/warning.svg)`,
  };

  return markers[marker];
};

export const getElevation = (level: string) => {
  const elevations: Record<string, string> = {
    ["level0"]: `border: 1px solid rgba(68, 89, 121, 0.06);`,
    ["level1"]: `border: 1px solid rgba(68, 89, 121, 0.06);box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.06);`,
  };
  return elevations[level];
};

export const getBorderRadius = (size: string) => {
  const radius: Record<string, string> = {
    ["large"]: borderRadiusLarge,
    ["medium"]: borderRadiusMedium,
    ["small"]: borderRadiusSmall,
  };
  return radius[size];
};

export const getStyles = (props: StyleProps) => {
  const {
    background,
    border,
    borderRadius,
    boxSizing = "content-box",
    card,
    center = { element: false, text: false },
    outline = false,
  } = props;

  const cssString = `
  ${background ? `background: ${background}` : null};
  ${border?.bottom ? `border-bottom: ${borderStandard}` : null};
  ${border?.top ? `border-top: ${borderStandard}` : null};
  ${border?.left ? `border-left: ${borderStandard}` : null};
  ${border?.right ? `border-right: ${borderStandard}` : null};
  ${borderRadius ? `border-radius: ${getBorderRadius(borderRadius)}` : null};
  ${card?.elevation ? getElevation(card.elevation) : null};
  box-sizing: ${boxSizing};
  ${center.element ? "justify-self: center" : null};
  ${outline ? `outline: ${borderStandard}` : null};
  overflow: hidden;
  ${center.text ? "text-align: center" : null};
`;
  return cssString;
};
