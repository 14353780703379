import type { AlgoliaSearchDocument } from "@10x/algolia-utils/search-documents";
import { getHref as getSupportDocumentHref } from "@10x/ui/src/Support/SiteSearch/HitComponents/utils";

export const getBreadcrumb = (hit: AlgoliaSearchDocument) => {
  const section = hit.category === "support" ? "Support Document" : "Document";
  return [section, hit.docType];
};

export const getThumbnail = (hit: AlgoliaSearchDocument) => {
  const { attachments, category, thumbnailUrl, title } = hit;

  const thumbnail =
    category === "support" ? attachments?.[0]?.thumbnailHref : thumbnailUrl;

  return thumbnail
    ? {
        alt: title,
        src: thumbnail,
      }
    : {
        alt: "Support document",
        src: "https://cdn.10xgenomics.com/image/upload/v1651769650/support/documents/documentation_default.png",
      };
};

export const getHref = (hit: AlgoliaSearchDocument) => {
  const { category, slug, steps } = hit;

  if (category === "support") {
    const step = steps[0];
    return getSupportDocumentHref(step, hit as any);
  } else {
    return `/library/${slug}`;
  }
};
