import { css } from "@emotion/react";

import type { Props as ContainerProps } from "./Container";
import Container from "./Container";
import type { Props as StackProps } from "./Stack";
import Stack from "./Stack";

interface Props {
  cellWidth?: string;
  container?: ContainerProps;
  gap?: string;
  items: StackProps[];
  width?: string;
}

const Grid = ({
  container,
  cellWidth = "360px",
  gap = "60px",
  items,
  width = "100%",
}: Props) => {
  return (
    <Container {...container}>
      <ul
        css={css`
          display: grid;
          gap: ${gap};
          grid-template-columns: repeat(auto-fit, minmax(${cellWidth}, 1fr));
          list-style: none;
          margin: 0;
          max-width: ${width};
          padding: 0;
          > li {
            width: auto;
          }
        `}
      >
        {items.map((item, index) => {
          return (
            <Stack
              container={item.container}
              content={item.content}
              key={index}
            />
          );
        })}
      </ul>
    </Container>
  );
};

export default Grid;
