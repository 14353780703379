import type { AlgoliaPageRecord } from "@10x/algolia-utils/pages";
import { css } from "@emotion/react";
import type { Hit } from "react-instantsearch-core";

import Anchor from "../../Anchor";
import Heading from "../../Heading";
import Text from "../../Text";
import Mark from "../Connectors/Mark";
import Snippet from "../Connectors/Snippet";
import type { HitComponent } from "../types";
import Breadcrumb from "./Breadcrumb";

const Page: HitComponent<Hit<AlgoliaPageRecord>> = ({ hit }) => {
  return (
    <>
      <Anchor href={hit.path.join("/")}>
        <Heading
          as={"h3"}
          color={"inherit"}
          css={css`
            margin-bottom: 0.5rem;
          `}
          responsive={true}
          size={"medium"}
          weight={"regular"}
        >
          <Mark attribute={"title"} hit={hit} />
        </Heading>
      </Anchor>
      <Text
        as={"p"}
        color={"midgray"}
        css={css`
          margin-bottom: 0.5rem;
        `}
        responsive={true}
        size={"medium"}
      >
        <Snippet attribute={"description"} hit={hit} />
      </Text>
      <Breadcrumb path={hit.path} />
    </>
  );
};

export default Page;
