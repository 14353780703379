import type { BlogPostsIndexRecord } from "@10x/algolia-utils/blog-posts";
import { colorWhite, mediaTabletLandscape } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { MDXRemote } from "next-mdx-remote";
import type { FunctionComponent } from "react";
import Truncate from "react-truncate";

import Anchor from "../../Anchor";
import MDXComponents from "../../Blocks/MDX/MDXComponents";
import Image from "../../Image";
import Text from "../../Text";
import Author from "../Author";
import { transformImageSize } from "../utils";

interface Props {
  featuredBlogPost: BlogPostsIndexRecord;
}

const ImageCss = css`
  align-items: center;
  display: flex;
  flex-basis: 50%;
  flex-shrink: 0;
  height: 338px;
  margin-right: 3rem;
  max-width: 600px;
  overflow: hidden;

  @media (max-width: ${mediaTabletLandscape}) {
    margin-right: 0;
    margin-bottom: 2rem;
    max-width: 100%;
  }
`;

const featuredCss = css`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;

  @media (max-width: ${mediaTabletLandscape}) {
    flex-direction: column;
  }
`;

const FeaturedArticleInfo: FunctionComponent<Props> = ({
  featuredBlogPost,
}) => {
  const { author, mdx, socialImage, slug, title } = featuredBlogPost;

  const transformedImage = transformImageSize(socialImage, "w_600");
  return (
    <div css={featuredCss}>
      <Anchor css={ImageCss} href={`/blog/${slug}`}>
        <Image alt={title} src={transformedImage}></Image>
      </Anchor>
      <div>
        <Text
          as={"div"}
          color={"steelLight"}
          css={css`
            margin-bottom: 1rem;
            text-transform: uppercase;
          `}
          size={"small"}
          weight={"medium"}
        >
          {"Featured"}
        </Text>
        <Anchor color={"white"} href={`/blog/${slug}`}>
          <Text
            as={"div"}
            color={"white"}
            css={css`
              line-height: 3rem;
              margin-bottom: 1rem;
            `}
            size={"xxlarge"}
            weight={"semibold"}
          >
            {title}
          </Text>
        </Anchor>
        <Anchor href={`/blog/${slug}`}>
          <Text
            as={"div"}
            color={"steelLight"}
            css={css`
              margin-bottom: 1.5rem;
            `}
            size={"medium"}
            weight={"regular"}
          >
            <Truncate ellipsis={<span>{"..."}</span>} lines={3} width={542}>
              <MDXRemote {...mdx} components={MDXComponents} />
            </Truncate>
          </Text>
        </Anchor>

        <Author
          author={author}
          css={css`
            color: ${colorWhite};
          `}
        />
      </div>
    </div>
  );
};

export default FeaturedArticleInfo;
