import {
  borderRadiusMedium,
  borderStandard,
  colorBlueLightest,
  colorSteelDarkest,
  fontFamilyMonospace,
  fontSizeXsmall,
  sizeXlarge,
} from "@10xdev/design-tokens";
import { css, jsx } from "@emotion/react";
import type { JSXElementConstructor, ReactElement, ReactNode } from "react";

export const preCss = css`
  border: ${borderStandard};
  box-sizing: border-box;
  border-radius: ${borderRadiusMedium};
  white-space: pre;
`;

export const codeCss = css`
  background: ${colorBlueLightest};
  color: ${colorSteelDarkest};
  display: block;
  font-family: ${fontFamilyMonospace};
  font-size: ${fontSizeXsmall};
  font-weight: normal;
  padding: ${sizeXlarge};
`;

export const highlightCss = css`
  background: ${colorBlueLightest};
  border: ${borderStandard};
  box-sizing: border-box;
  border-radius: ${borderRadiusMedium};
  overflow: scroll;
`;

export const cloneElement = (
  element: ReactElement<
    {
      children?: ReactNode;
      className: string;
    },
    string | JSXElementConstructor<any>
  >,
  props: any,
) =>
  jsx(element.type, {
    key: element.key,
    ...element.props,
    ...props,
  });

export const replaceLast = (
  str: string,
  pattern: RegExp,
  replacement: string,
) => {
  const match =
    typeof pattern === "string"
      ? pattern
      : (str.match(new RegExp(pattern.source, "g")) || []).slice(-1)[0];
  if (!match) return str;
  const last = str.lastIndexOf(match);
  return last !== -1
    ? `${str.slice(0, last)}${replacement}${str.slice(last + match.length)}`
    : str;
};
