import type { AnalysisGuideIndexRecord } from "@10x/algolia-utils/analysis-guides";
import { css } from "@emotion/react";
import { compact } from "lodash-es";
import type { Hit } from "react-instantsearch-core";

import Anchor from "../../Anchor";
import Heading from "../../Heading";
import Text from "../../Text";
import Mark from "../Connectors/Mark";
import Snippet from "../Connectors/Snippet";
import type { HitComponent } from "../types";
import Breadcrumb from "./Breadcrumb";

const AnalysisGuide: HitComponent<Hit<AnalysisGuideIndexRecord>> = ({
  hit,
}) => {
  const analysisGuidesPath = compact(["Resources", "Analysis Guides"]);

  return (
    <>
      <Anchor href={`/analysis-guides/${hit.slug}`}>
        <Heading
          as={"h3"}
          color={"inherit"}
          css={css`
            margin-bottom: 0.5rem;
          `}
          responsive={true}
          size={"medium"}
          weight={"regular"}
        >
          <Mark attribute={"title"} hit={hit} />
        </Heading>
      </Anchor>
      <Text
        as={"p"}
        color={"midgray"}
        css={css`
          margin-bottom: 0.5rem;
        `}
        responsive={true}
        size={"medium"}
      >
        {hit.summary ? <Snippet attribute={"summary"} hit={hit} /> : null}
      </Text>
      <Breadcrumb path={analysisGuidesPath} />
    </>
  );
};

export default AnalysisGuide;
