import {
  borderStandard,
  colorBlueDark,
  colorSteelLighter,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Text from "../../Text";
import DocumentThumbnail from "../DocumentThumbnail";
import { getDocumentDetailCopy } from "./utils";

interface Props {
  cgNumber?: string | null;
  documentType: string;
  image: {
    alt: string;
    src: string;
  } | null;
  link: string;
  subtext?: string | null;
  title: string;
}

const anchorCss = css`
  align-items: flex-start;
  border-bottom: ${borderStandard};
  display: flex;
  gap: 1.5rem;
  max-width: 760px;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const DocumentCard: FunctionComponent<Props> = ({
  cgNumber,
  documentType,
  image,
  link,
  subtext,
  title,
}) => {
  const documentId = getDocumentDetailCopy({
    cgNumber,
    documentType,
  });

  return (
    <Anchor
      color={"blue"}
      css={anchorCss}
      hoverColor={colorBlueDark}
      href={link}
    >
      <DocumentThumbnail
        css={css`
          border: 1px solid ${colorSteelLighter};
          height: 94px;
          width: 72px;
        `}
        filename={image?.alt}
        src={image?.src}
      />

      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 8px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 4px;
          `}
        >
          {documentId ? (
            <Text
              as={"div"}
              color={"steelMedium"}
              size={"small"}
              weight={"medium"}
            >
              {documentId}
            </Text>
          ) : null}
          <Text
            as={"div"}
            color={"inherit"}
            size={"medium"}
            weight={"semibold"}
          >
            {title}
          </Text>
        </div>
        {subtext && (
          <Text as={"div"} color={"steelDarkest"} size={"small"}>
            {subtext}
          </Text>
        )}
      </div>
    </Anchor>
  );
};

export default DocumentCard;
