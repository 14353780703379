import type { HeadingElement, TextSize } from "@10x/types";
import { colorBlueDark, colorSteelDarkest } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { forwardRef, useCallback, useState } from "react";

import Icon from "../../Icon";
import Text from "../../Text";

interface Props {
  as?: HeadingElement;
  className?: string;
  id: string;
  size?: TextSize;
  title: string;
}

const LinkableTitle = forwardRef<HTMLButtonElement, Props>(
  (
    { as: headingAs = "h3", className, id, size = "large", title }: Props,
    ref,
  ) => {
    const [copied, setCopied] = useState<boolean>(false);

    const handleClick = () => {
      const currentUrl = window.location.href.split(/[?#]/)[0];
      navigator.clipboard.writeText(`${currentUrl}#${id}`);
      setCopied(true);
    };

    const handleMouseLeave = useCallback(() => {
      setCopied(false);
    }, []);

    return (
      <button
        className={className}
        css={css`
          align-items: center;
          background: none;
          border: none;
          color: ${colorSteelDarkest};
          cursor: pointer;
          display: flex;
          gap: 0.75rem;
          margin-bottom: 1rem;
          padding: 0;

          :hover {
            color: ${colorBlueDark};
          }

          :hover svg {
            opacity: 1;
          }

          svg {
            opacity: 0;
            transition: opacity 0.5s;
          }
        `}
        onClick={handleClick}
        onMouseLeave={handleMouseLeave}
        ref={ref}
      >
        <Text
          as={headingAs as keyof JSX.IntrinsicElements}
          color={"inherit"}
          css={css`
            flex-wrap: wrap;
            text-align: left;
          `}
          id={id}
          size={size}
          weight={"semibold"}
        >
          {title}
        </Text>

        {copied ? (
          <Text as={"span"} color={"steelMedium"} size={"xsmall"}>
            {"Copied!"}
          </Text>
        ) : (
          <Icon
            css={css`
              flex-shrink: 0;
              padding: 0 1.5rem 0 0;
            `}
            size={"1.5rem"}
            source={"copy-link"}
          />
        )}
      </button>
    );
  },
);

LinkableTitle.displayName = "LinkableTitle";

export default LinkableTitle;
