import {
  borderStandard,
  fontSizeSmall,
  fontWeightRegular,
  fontWeightSemibold,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import { Anchor } from "../..";

interface Props {
  currentSlug?: string;
  list?: Array<{
    fullName?: string;
    slug?: string;
  }>;
  type?: "board" | "team";
}

const NavList: FunctionComponent<Props> = ({ currentSlug, list, type }) => {
  return (
    <nav>
      <ul
        css={css`
          list-style: none;
          margin: 0 0 3rem;
          padding: 0;
        `}
      >
        {list?.map((navItem) => {
          const { fullName, slug } = navItem;
          const isCurrent = slug === currentSlug;
          return (
            <li
              css={css`
                border-bottom: ${borderStandard};
                padding: 0.5rem 0;
              `}
              key={slug}
            >
              <Anchor
                color={isCurrent ? "base" : "midgray"}
                css={{
                  display: "block",
                  fontSize: fontSizeSmall,
                  fontWeight: isCurrent
                    ? fontWeightSemibold
                    : fontWeightRegular,
                  marginBottom: ".25rem",
                }}
                href={`/company/${type}/${slug}`}
                scroll={false}
                target={"_self"}
              >
                {fullName}
              </Anchor>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default NavList;
