import type { GetAllEventsQuery } from "@10x/types/__generated__/graphql-types";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Text from "../Text";
import ThematicBreak from "../ThematicBreak";

interface Props {
  collapsed?: boolean;
  event: NonNullable<GetAllEventsQuery["events"]>[0];
}

/**
 * A list of products related to an event.
 */
const Products: FunctionComponent<Props> = ({ collapsed, event }) => {
  const { products } = event;

  return products && products.length ? (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: ${collapsed ? "216px" : "240px"};
      `}
    >
      <ThematicBreak
        css={css`
          margin: 0 0 8px 0;
        `}
      />

      <Text
        as={"h4"}
        css={css`
          margin-bottom: 16px;
        `}
        size={"small"}
        weight={"semibold"}
      >
        {"Products"}
      </Text>

      <ul
        css={css`
          list-style: none;
          margin: 0;
          padding: 0;
        `}
      >
        {products.map((product) =>
          product.name ? (
            <li
              css={css`
                align-items: flex-start;
                display: flex;
                gap: 12px;
                margin-bottom: 12px;
              `}
              key={product.slug}
            >
              <Text as={"span"} size={"small"} weight={"regular"}>
                {product.name}
              </Text>
            </li>
          ) : null,
        )}
      </ul>
    </div>
  ) : null;
};

export default Products;
