import type { LinkItem } from "@10x/cms/types";
import { colorBlueDark } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import type { TextAlign, TextSize } from "../../../types";
import Anchor from "../Anchor";
import ConditionalLink from "../ConditionalLink";
import Icon from "../Icon";
import Image from "../Image";
import Text from "../Text";

interface Props {
  alignLayout?: "flex-start" | "center" | "flex-end" | "stretch";
  alignText?: TextAlign;
  description?: string;
  image?: {
    alt?: string;
    elevate?: boolean;
    url: string;
    width?: string;
  };
  link?: LinkItem;
  padding?: string;
  reverse?: boolean;
  title?: string;
  titleSize?: TextSize;
}

const DisplayCard: FunctionComponent<Props> = ({
  alignLayout = "flex-start",
  alignText = "left",
  description,
  image,
  link,
  padding = "0",
  reverse = false,
  title,
  titleSize = "large",
}) => {
  return (
    <li
      css={css`
        align-items: ${alignLayout};
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
      `}
    >
      {image?.url ? (
        <div
          css={css`
            order: ${reverse ? 2 : 1};
          `}
        >
          <ConditionalLink href={link?.url}>
            <Image
              alt={image.alt}
              css={css`
                box-shadow: ${image.elevate
                  ? "0px 2px 5px rgba(0, 0, 0, 0.12)"
                  : "none"};
              `}
              src={image.url}
              width={image.width || "100%"}
            />
          </ConditionalLink>
        </div>
      ) : null}

      <div
        css={css`
          order: ${reverse ? 1 : 2};
          padding: ${padding};
        `}
      >
        {title ? (
          <Text
            as={"p"}
            css={css`
              margin-bottom: ${reverse ? "1rem" : "1.5rem"};
              text-align: ${alignText};
            `}
            responsive={true}
            size={titleSize}
            weight={"semibold"}
          >
            {title}
          </Text>
        ) : null}

        {description ? (
          <Text
            as={"div"}
            color={"midgray"}
            css={css`
              margin-bottom: 1rem;
              text-align: ${alignText};
            `}
            responsive={true}
            size={"medium"}
          >
            {description}
          </Text>
        ) : null}

        {link?.url ? (
          <Text
            as={"div"}
            color={"blue"}
            css={css`
              text-align: ${alignText};
            `}
            size={"small"}
            weight={"semibold"}
          >
            <Anchor
              color={"inherit"}
              hoverColor={colorBlueDark}
              href={link.url}
              target={link.target}
            >
              {link.label}
              {link.type ? (
                <Icon
                  size={"12px"}
                  source={link.type}
                  xPos={"16px"}
                  yPos={"1px"}
                />
              ) : null}
            </Anchor>
          </Text>
        ) : null}
      </div>
    </li>
  );
};

export default DisplayCard;
