import { SOFTWARE_EULA_KEY } from "@10x/site/utils/local-storage-keys";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { useLocalStorage } from "react-use";

import { getDownloadUrl } from "./utils";

/**
 * Triggers a download of a Loupe installer file.
 *
 * If the URL query has a "start" parameter attached,
 * the user was redirected to the Loupe Downloads
 * page from the Software EULA, which they just
 * successfully signed. Rather than have them hit
 * the download link again, we want the download
 * to just start.
 *
 * This hook imperatively clicks the right link.
 */
export const useLoupeAutoDownload = () => {
  const ref = useRef<any>(null);

  const { asPath, query, replace } = useRouter();
  const [downloadDidStart, setDownloadDidStart] = useState(false);

  /* eslint-disable react-hooks/exhaustive-deps */
  // If we add all the dependencies that the linter wants,
  // the effect no longer fires as expected.
  useEffect(() => {
    if (
      !downloadDidStart &&
      ref?.current?.getAttribute("href")?.includes(query.start)
    ) {
      // Don't run this again
      setDownloadDidStart(true);

      // Strip the start param so we don't accidentally
      // trigger multiple downloads
      replace(asPath.split("?")[0]);

      // Imperative click to get the download going
      ref.current.click();
    }
  });
  /* eslint-enable react-hooks/exhaustive-deps */

  return { ref };
};

/**
 * Returns the URL of either a Loupe installer file or
 * the URL of the Software EULA, if the user has not
 * signed it before.
 *
 * The value of softwareEULA is always false when the
 * Software Downloads page is generated server-side,
 * because there's no window.localStorage in the server
 * environment. And then when everything fires up
 * client-side, the download URLs do not get
 * regenerated to reflect the correct value of that
 * flag. So we brute-force an update with useEffect.
 */
export const useDownloadURL = (url: string, softwareSlug: string) => {
  const { asPath } = useRouter();
  const [href, setHref] = useState("");
  const [softwareEULA] = useLocalStorage(SOFTWARE_EULA_KEY, false);

  useEffect(() => {
    setHref(getDownloadUrl(softwareEULA as boolean, url, asPath, softwareSlug));
  }, [asPath, url, softwareEULA, softwareSlug]);

  return {
    href,
    softwareEULA,
  };
};
