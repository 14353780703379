import type { GetAllEventsQuery } from "@10x/types/__generated__/graphql-types";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Icon from "../Icon";
import Text from "../Text";
import Details from "./Details";
import Products from "./Products";
import ResearchAreas from "./ResearchAreas";

interface Props {
  collapsed?: boolean;
  event: NonNullable<GetAllEventsQuery["events"]>[0];
}

/**
 * A card that displays a summary view of an event.
 */
const Sidebar: FunctionComponent<Props> = ({ collapsed = true, event }) => {
  return (
    <div
      css={css`
        display: flex;
        flex: 1;
        flex-direction: column;
        width: ${collapsed ? "226px" : "240px"};
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 36px;
        `}
      >
        <Details collapsed={collapsed} event={event} />

        {collapsed ? null : (
          <>
            <ResearchAreas event={event} />
            <Products event={event} />
          </>
        )}
      </div>

      {collapsed ? (
        <div
          css={css`
            margin-top: auto;
            padding-bottom: 16px;
          `}
        >
          <Text
            as={"span"}
            color={"blue"}
            css={css`
              margin-right: 14px;
            `}
            size={"small"}
            weight={"semibold"}
          >
            {"More info"}
          </Text>
          <Icon
            color={"blue"}
            size={"11px"}
            source={"nav-right"}
            yPos={"1px"}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Sidebar;
