import type { ServiceProvidersIndexRecord } from "@10x/algolia-utils/service-providers";
import {
  colorBlueDark,
  mediaPhoneOnly,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../Anchor";
import Icon from "../Icon";
import Image from "../Image";
import Text from "../Text";
import LinkList from "./LinkList";

interface Props {
  hit: ServiceProvidersIndexRecord;
}

const ProviderInfo: FunctionComponent<Props> = ({ hit }: Props) => {
  return (
    <div
      css={css`
        display: flex;
        padding: 1.5rem 2rem 0.5rem 0;

        @media (max-width: ${mediaPhoneOnly}) {
          flex-direction: column;
        }
      `}
    >
      <div
        css={css`
          flex-shrink: 0;
          margin-right: 16%;

          @media (max-width: ${mediaTabletLandscape}) {
            margin-right: 10%;
          }

          @media (max-width: ${mediaPhoneOnly}) {
            margin-bottom: 2rem;
          }
        `}
      >
        {hit.url ? (
          <Anchor
            aria-label={`view ${hit.name} website`}
            color={"blue"}
            css={{
              alignItems: "center",
              display: "flex",
              marginBottom: "1.5rem",
            }}
            hoverColor={colorBlueDark}
            href={hit.url}
            target={"_blank"}
          >
            <Text
              as={"span"}
              color={"inherit"}
              css={css`
                display: block;
                margin-right: 1rem;
              `}
              size={"small"}
              weight={"semibold"}
            >
              {"View Service"}
            </Text>
            <Icon size={"14px"} source={"external-link"} />
          </Anchor>
        ) : null}
        <Image alt={hit.name} src={hit.image} width={"148px"} />
      </div>
      <div className={"DescriptionServices"}>
        {hit.description ? (
          <Text
            as={"p"}
            color={"steelDarker"}
            css={css`
              margin-bottom: 1.5rem;
            `}
            size={"medium"}
            weight={"regular"}
          >
            {hit.description}
          </Text>
        ) : null}
        <Text
          as={"div"}
          color={"steelDarker"}
          css={css`
            margin-bottom: 0.5rem;
          `}
          size={"small"}
          weight={"medium"}
        >
          {"Services"}
        </Text>
        <LinkList
          products={hit.products}
          serviceTypes={hit.serviceTypes || []}
        />
      </div>
    </div>
  );
};

export default ProviderInfo;
