import {
  borderStandard,
  colorBlueMedium,
  fontSizeMedium,
  fontWeightRegular,
  fontWeightSemibold,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import { Anchor } from "../..";

interface Props {
  active?: "board" | "team";
  sections?: Array<{
    label?: string;
    slug?: string;
  }>;
}

const NavTabs: FunctionComponent<Props> = ({ active, sections }) => {
  return (
    <div
      css={css`
        border-bottom: ${borderStandard};
      `}
    >
      <ul
        css={css`
          display: flex;
          list-style: none;
          margin: 0 auto;
          max-width: 1200px;
          padding: 0;
        `}
      >
        {sections?.map((section) => {
          const { label, slug } = section;
          const isCurrent = slug === active;
          return (
            <li
              css={css`
                cursor: pointer;
                background: none;
                border: none;
                margin: 0 4rem 0 0;
                padding: 0;
              `}
              key={slug}
            >
              <Anchor
                color={isCurrent ? "base" : "midgray"}
                css={{
                  borderBottom: isCurrent
                    ? `solid 4px ${colorBlueMedium}`
                    : "solid 4px transparent",
                  display: "block",
                  fontSize: fontSizeMedium,
                  fontWeight: isCurrent
                    ? fontWeightSemibold
                    : fontWeightRegular,
                  padding: "1.5rem 0 1.25rem",
                }}
                href={`/company/${slug}`}
                scroll={false}
                target={"_self"}
              >
                {label}
              </Anchor>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default NavTabs;
