import { colorBlueDark, colorBlueMedium } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { ReactNode } from "react";

import Text from "../../Text";

interface Props {
  active: boolean;
  children: ReactNode;
  nested?: boolean | null;
}

export default function Item({ active, nested, children }: Props) {
  return (
    <Text
      as={"li"}
      color={active ? "steelDarkest" : "steelDarker"}
      css={css`
        &:hover {
          color: ${colorBlueDark};
          cursor: pointer;
        }
        border-left-color: ${active ? colorBlueMedium : "transparent"};
        border-left-style: solid;
        border-left-width: 2px;
        display: flex;
        height: 100%;
        list-style: none;
        padding-left: ${nested ? "2rem" : "1rem"};
      `}
      size={"small"}
      weight={active ? "medium" : "regular"}
    >
      {children}
    </Text>
  );
}
