import { colorBlueDark, mediaPhoneOnly } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../Anchor";
import Icon from "../Icon";
import Text from "../Text";

interface Props {
  slug: string;
  title: string;
}

const GuideCitation: FunctionComponent<Props> = ({ slug, title }) => {
  const socialPostUrl = `https://10xgenomics.com/analysis-guides/${slug}`;
  return (
    <div
      css={css`
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.5rem;
      `}
    >
      <div
        css={css`
          display: flex;
        `}
      >
        <div
          css={css`
            @media (max-width: ${mediaPhoneOnly}) {
              display: none;
            }
          `}
        >
          <Text
            as={"div"}
            color={"gray"}
            css={css`
              margin-right: 1.25rem;
            `}
            size={"small"}
            weight={"regular"}
          >
            {"Share via:"}
          </Text>
        </div>

        <Anchor
          color={"gray"}
          css={{ marginRight: ".75rem" }}
          hoverColor={colorBlueDark}
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${socialPostUrl}&title=${title}`}
          target={"_blank"}
        >
          <Icon
            color={"inherit"}
            css={{ marginRight: "12px" }}
            size={"24px"}
            source={"linkedin"}
          />
        </Anchor>

        <Anchor
          color={"gray"}
          hoverColor={colorBlueDark}
          href={`https://twitter.com/intent/tweet?text=${title}&url=${socialPostUrl}`}
          target={"_blank"}
        >
          <Icon
            color={"inherit"}
            css={{ marginRight: "2.25rem" }}
            size={"24px"}
            source={"twitter"}
            yPos={"1px"}
          />
        </Anchor>
      </div>
    </div>
  );
};

export default GuideCitation;
