import { distributorsIndexName } from "@10x/algolia-utils/index-names";
import searchClient from "@10x/algolia-utils/searchOnlyClient";
import { Configure, InstantSearch } from "react-instantsearch-dom";

import DistributorsAccordions from "./DistributorsAccordions";
import Header from "./Header";

export default function Distributors() {
  return (
    <InstantSearch
      indexName={distributorsIndexName.toString()}
      searchClient={searchClient()}
    >
      <Configure hitsPerPage={1000} />
      <Header />
      <DistributorsAccordions />
    </InstantSearch>
  );
}
