import FACETS from "@10x/algolia-utils/videos/facets";

export const ASPECT_RATIO = 0.5625;
export const THUMBNAIL_WIDTH = 325;
export const THUMBNAIL_HEIGHT = Math.round(THUMBNAIL_WIDTH * ASPECT_RATIO);

export const VIDEO_FACETS = [
  {
    attribute: FACETS.categoryLabel,
    label: "Category",
  },
  {
    attribute: FACETS.productName,
    label: "Product",
  },
  {
    attribute: FACETS.researchAreas,
    label: "Area of Interest",
  },
  {
    attribute: FACETS.language,
    label: "Language",
  },
];
