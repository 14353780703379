import type { AlgoliaRecord } from "@10x/algolia-utils/videos";
import {
  borderRadiusMedium,
  borderStandard,
  boxShadowLight,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { format } from "date-fns";
import type { FunctionComponent } from "react";
import { useState } from "react";

import Anchor from "../Anchor";
import Card from "../Card";
import Icon from "../Icon";
import PlayButton from "../PlayButton";
import Text from "../Text";
import { THUMBNAIL_HEIGHT, THUMBNAIL_WIDTH } from "./constants";
import { getFormattedDuration, getThumbnailUrlWithSizes } from "./utils";

interface Props {
  video: AlgoliaRecord;
}

/**
 * A card that displays a summary view of an event.
 */
const VideoCard: FunctionComponent<Props> = ({ video }) => {
  const { category, created, duration, id, thumbnail, title } = video;

  const formattedCreationDate = created
    ? format(new Date(created), "MMM d, yyyy")
    : "";
  const formattedDuration = getFormattedDuration(duration);
  const thumbnailResized = getThumbnailUrlWithSizes(thumbnail.url);

  const [active, setActive] = useState(false);

  return (
    <Anchor
      css={css`
        display: block;
        margin: 0;
      `}
      href={`/videos/${id}?autoplay=true`}
      onBlur={() => setActive(false)}
      onFocus={() => setActive(true)}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      onMouseOver={() => setActive(true)}
    >
      <Card as={"article"} elevate={true} hoverable={true} outline={true}>
        <div
          css={css`
            box-sizing: border-box;
            cursor: pointer;
            display: flex;
            min-height: 216px;
            padding: 24px 72px 24px 24px;
            width: 100%;

            @media (max-width: ${mediaTabletLandscape}) {
              flex-direction: column;
              padding: 0;
            }
          `}
          role={"button"}
          tabIndex={-1}
        >
          <div
            css={css`
              align-items: center;
              background-image: url(${thumbnailResized});
              background-repeat: no-repeat;
              background-size: contain;
              border: ${borderStandard};
              border-radius: ${borderRadiusMedium};
              box-shadow: ${boxShadowLight};
              display: flex;
              height: ${THUMBNAIL_HEIGHT}px;
              justify-content: center;
              margin-right: 32px;
              overflow: hidden;
              position: relative;
              min-width: ${THUMBNAIL_WIDTH}px;

              @media (max-width: ${mediaTabletLandscape}) {
                border: none;
                border-radius: 0;
                box-shadow: none;

                ::before {
                  background: linear-gradient(
                    0deg,
                    rgba(21, 48, 87, 0.06),
                    rgba(21, 48, 87, 0.06)
                  );
                  content: "";
                  height: 100%;
                  left: 0;
                  opacity: 1;
                  position: absolute;
                  top: 0;
                  width: 100%;
                }
              }
            `}
          >
            <PlayButton active={active} />
          </div>

          <div
            css={css`
              align-content: space-between;
              display: flex;
              flex-direction: column;
              flex: 1;

              @media (max-width: ${mediaTabletLandscape}) {
                padding: 16px 32px;
              }
            `}
          >
            <div
              css={css`
                align-items: center;
                display: flex;
                justify-content: space-between;
                margin-bottom: 4px;

                :last-of-type {
                  display: none;
                }

                @media (max-width: ${mediaTabletLandscape}) {
                  :last-of-type {
                    display: block;
                  }
                }
              `}
            >
              <Text as={"h1"} color={"blue"} size={"xsmall"} weight={"medium"}>
                {category?.label}
              </Text>
            </div>

            <Text as={"h2"} size={"medium"} weight={"semibold"}>
              {title}
            </Text>

            <div
              css={css`
                align-items: center;
                display: flex;
                justify-content: space-between;
                margin-top: auto;

                @media (max-width: ${mediaTabletLandscape}) {
                  margin-top: 16px;

                  :last-of-type {
                    display: none;
                  }
                }
              `}
            >
              <div
                css={css`
                  align-items: center;
                  display: flex;
                  gap: 12px;
                `}
              >
                {duration ? (
                  <>
                    <Icon color={"gray"} size={"16px"} source={"clock"} />
                    <Text as={"span"} color={"steelDarker"} size={"small"}>
                      {formattedDuration}
                    </Text>
                  </>
                ) : null}
              </div>

              {created ? (
                <Text as={"span"} color={"steelDark"} size={"xxsmall"}>
                  {formattedCreationDate}
                </Text>
              ) : null}
            </div>
          </div>
        </div>
      </Card>
    </Anchor>
  );
};

export default VideoCard;
