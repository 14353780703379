import type { WithCompiledMdx } from "@10x/cms/types";
import type { GetBlogPostQuery } from "@10x/types/__generated__/graphql-types";
import {
  colorWhite,
  mediaTabletLandscape,
  sizeXlarge,
  sizeXxlarge,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { MDXRemote } from "next-mdx-remote";
import type { FunctionComponent } from "react";
import { memo } from "react";

import MDXComponents from "../../Blocks/MDX/MDXComponents";
import ScrollTopButton from "../../ScrollTopButton";
import BlogBackButton from "./BlogBackButton";
import BlogCitation from "./BlogCitation";
import BlogDetail from "./BlogDetail";
import BlogTitle from "./BlogTitle";

interface Props {
  data: WithCompiledMdx<NonNullable<GetBlogPostQuery["blogPost"]>>;
}

const BlogPost: FunctionComponent<Props> = ({ data }) => {
  const { author, mdx, slug, timestamp, title, researchAreas } = data;

  return (
    <>
      <article
        className={"BlogPost"}
        css={css`
          background-color: ${colorWhite};
          box-sizing: border-box;
          padding-top: 3rem;
          margin: 0 auto ${sizeXxlarge} auto;
          max-width: 720px;

          @media (max-width: ${mediaTabletLandscape}) {
            padding: ${sizeXlarge} 1.5rem;
          }
        `}
      >
        <header
          className={"BlogPostHeader"}
          css={css`
            margin-bottom: ${sizeXxlarge};

            @media (max-width: ${mediaTabletLandscape}) {
              text-align: left;
            }
          `}
        >
          <BlogBackButton />
          <BlogDetail researchAreas={researchAreas} timestamp={timestamp} />
          <BlogTitle title={title} />
          <BlogCitation author={author} slug={slug} title={title} />
        </header>

        <MDXRemote {...mdx} components={MDXComponents} />
      </article>
      <ScrollTopButton top={10} />
    </>
  );
};

export default memo(BlogPost);
