import StaticPropsContext from "@10x/site/contexts/static-props";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";
import { useContext } from "react";

import { LocationCard } from "../Cards";
import { REGION_LABELS } from "./constants";

const Locations: FunctionComponent = () => {
  const { locations, regions } = useContext(StaticPropsContext);

  const sortedLocations = regions?.map((region) => {
    const offices = locations?.filter((location) => {
      return location.region === region.slug;
    });
    return {
      ...region,
      offices,
    };
  });

  return (
    <ul
      css={css`
        list-style: none;
        margin: 0;
        padding: 0;
      `}
    >
      {sortedLocations?.length
        ? sortedLocations.map((region) => {
            const { contactSales, offices, slug } = region;
            const title = `${REGION_LABELS[slug]} Locations`;

            return (
              <LocationCard
                action={contactSales}
                key={title}
                locations={offices || []}
                title={title}
              />
            );
          })
        : null}
    </ul>
  );
};

export default Locations;
