import { colorSteelLighter } from "@10xdev/design-tokens";
import type { ReactNode } from "react";

import Text from "../../Text";

interface Props {
  children: ReactNode;
  className?: string;
  title: string;
}

export default function OnThisPage({ className, children, title }: Props) {
  return (
    <nav
      className={className}
      css={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        width: "240px",
      }}
    >
      <Text
        as={"h1"}
        color={"steelMedium"}
        css={{
          textTransform: "uppercase",
        }}
        size={"xsmall"}
        weight={"semibold"}
      >
        {title}
      </Text>
      <ul
        css={{
          "& li": {
            marginLeft: "0px",
          },
          borderLeftColor: colorSteelLighter,
          borderLeftStyle: "solid",
          borderLeftWidth: "1px",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          margin: 0,
          maxHeight: "calc(100vh - 286px)",
          overflow: "scroll",
          padding: 0,
        }}
      >
        {children}
      </ul>
    </nav>
  );
}
