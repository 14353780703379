import type { TextColor } from "@10x/types";
import { sizeXsmall } from "@10xdev/design-tokens";
import type { CSSProperties, FunctionComponent, ReactNode } from "react";

import Text from "../Text";

interface Props {
  children: ReactNode;
  color?: TextColor;
  responsive: boolean;
  size?: "small" | "medium" | "large";
  style?: CSSProperties;
}

const ListItem: FunctionComponent<Props> = ({
  children,
  color = "base",
  responsive,
  style,
  size = "small",
}) => {
  return (
    <Text
      as={"li"}
      color={color}
      responsive={responsive}
      size={size}
      style={{
        marginBottom: sizeXsmall,
        ...style,
      }}
      weight={"regular"}
    >
      {children}
    </Text>
  );
};

export default ListItem;
