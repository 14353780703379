import type { Section } from "@10x/cms/definitions/blocks/section";
import { css } from "@emotion/react";
import { Fragment } from "react";
import { v4 as uuidv4 } from "uuid";

import type { FlexProps } from "../../../Flex";
import Flex from "../../../Flex";
import type { StyleProps } from "../types";
import { getContent, getStyles } from "../utils";

export interface Props {
  content: Section[];
  layout?: FlexProps;
  styles?: StyleProps;
}

const FlexGroup = ({ content, layout, styles }: Props) => {
  const cssString = styles ? getStyles(styles) : "";
  return (
    <Flex
      {...layout}
      css={css`
        ${cssString}
      `}
    >
      {content?.map((item) => {
        return <Fragment key={uuidv4()}>{getContent(item)}</Fragment>;
      })}
    </Flex>
  );
};

export default FlexGroup;
