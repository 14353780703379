import { serviceProvidersLocaleToIndexName } from "@10x/algolia-utils/index-names";
import type { Locale } from "@10x/cms/types";
import { useRouter } from "next/router";

import Search from "../Search";
import Filters from "../Search/Filters";
import { SERVICE_PROVIDER_FACETS } from "./constants";
import List from "./List";

const ServiceProviders = () => {
  const router = useRouter();
  const locale = (router.locale as Locale | undefined) ?? "en";
  return (
    <>
      <Search
        hitsRenderer={({ onHits }) => {
          return <List onHits={onHits} />;
        }}
        includeStats={false}
        indexName={serviceProvidersLocaleToIndexName[locale].toString()}
        placeholder={"Search service providers"}
        sidebar={
          <Filters facets={SERVICE_PROVIDER_FACETS} title={"Providers"} />
        }
      />
    </>
  );
};

export default ServiceProviders;
