import {
  analysisGuidesIndexName,
  blogPostsIndexName,
  datasetsIndexName,
  pagesIndexName,
  publicationsIndexName,
  searchDocumentsIndexName,
  softwarePagesIndexName,
  userGuidesIndexName,
  videosIndexName,
} from "@10x/algolia-utils/index-names";

import Software from "../Support/SiteSearch/HitComponents/Page";
import AnalysisGuide from "./Hits/AnalysisGuide";
import Blog from "./Hits/Blog";
import Dataset from "./Hits/Dataset";
import Document from "./Hits/Document";
import Page from "./Hits/Page";
import Publication from "./Hits/Publication";
import UserGuide from "./Hits/UserGuide";
import Video from "./Hits/Video";
import type { HitComponent } from "./types";

export type Index = {
  filters?: string;
  hitComponent: HitComponent;
  hitsPerPage: number;
  id: string;
  indexName: string;
  pageLink?: {
    label: string;
    url: string;
  };
  title: string;
};

export type IndexWithHits = (typeof INDICES)[number];

export const INDICES = [
  {
    hitComponent: Page,
    hitsPerPage: 8,
    id: pagesIndexName.baseName,
    indexName: pagesIndexName.toString(),
    title: "Pages",
  },
  {
    hitComponent: Document,
    hitsPerPage: 8,
    id: searchDocumentsIndexName.baseName,
    indexName: searchDocumentsIndexName.toString(),
    pageLink: {
      label: "View Library",
      url: "/library",
    },
    title: "Library",
  },
  {
    hitComponent: Publication,
    hitsPerPage: 8,
    id: publicationsIndexName.baseName,
    indexName: publicationsIndexName.toString(),
    pageLink: {
      label: "View Publications page",
      url: "/publications",
    },
    title: "Publications",
  },
  {
    hitComponent: Software,
    hitsPerPage: 8,
    id: softwarePagesIndexName.baseName,
    indexName: softwarePagesIndexName.toString(),
    title: "Software",
  },
  {
    hitComponent: Dataset,
    hitsPerPage: 8,
    id: datasetsIndexName.baseName,
    indexName: datasetsIndexName.toString(),
    pageLink: { label: "View Datasets page", url: "/datasets" },
    title: "Datasets",
  },
  {
    hitComponent: UserGuide,
    hitsPerPage: 8,
    id: userGuidesIndexName.baseName,
    indexName: userGuidesIndexName.toString(),
    pageLink: { label: "View User Guides page", url: "/support/user-guides" },
    title: "User Guides",
  },
  {
    hitComponent: AnalysisGuide,
    hitsPerPage: 8,
    id: analysisGuidesIndexName.baseName,
    indexName: analysisGuidesIndexName.toString(),
    pageLink: {
      label: "View Analysis Guides page",
      url: "/analysis-guides",
    },
    title: "Analysis Guides",
  },
  {
    filters: "language:English",
    hitComponent: Video,
    hitsPerPage: 6,
    id: videosIndexName.baseName,
    indexName: videosIndexName.toString(),
    pageLink: { label: "View Videos page", url: "/videos" },
    title: "Videos",
  },
  {
    hitComponent: Blog,
    hitsPerPage: 6,
    id: blogPostsIndexName.baseName,
    indexName: blogPostsIndexName.toString(),
    pageLink: { label: "View Blog", url: "/blog" },
    title: "Blog",
  },
];
