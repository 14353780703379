import type { Distributor as DistributorInterface } from "@10x/cms/model/distributor";
import { mediaPhoneOnly } from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import Anchor from "../Anchor";
import Text from "../Text";
import { trimUrl } from "./utils";

export function Distributor({
  distributor,
}: {
  distributor: DistributorInterface;
}) {
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        padding: 2rem 2.5rem;

        @media (max-width: ${mediaPhoneOnly}) {
          flex-direction: column;
        }
      `}
    >
      <div
        css={css`
          width: 358px;

          @media (max-width: ${mediaPhoneOnly}) {
            margin-bottom: 8px;
          }
        `}
      >
        <Text as={"div"} size={"large"} weight={"semibold"}>
          {distributor.country}
        </Text>
      </div>
      <div
        css={css`
          width: 300px;
        `}
      >
        <Text
          as={"h5"}
          css={css`
            margin-bottom: 8px;
          `}
          size={"medium"}
          weight={"semibold"}
        >
          {distributor.name}
        </Text>
        <Text as={"address"} size={"medium"} weight={"regular"}>
          {distributor.address}
        </Text>
      </div>
      <div
        css={css`
          width: 300px;

          > * {
            margin-bottom: 8px;
          }
        `}
      >
        {distributor.phoneNumbers &&
          distributor.phoneNumbers.map(({ type, number }) => {
            return (
              <Text
                as={"p"}
                key={`${type}+${number}`}
                size={"medium"}
                weight={"regular"}
              >
                {type}
                {": "}
                {number}
              </Text>
            );
          })}
        {distributor.website && (
          <div>
            <Text as={"span"} size={"medium"} weight={"regular"}>
              {"Website: "}
            </Text>
            <Text as={"span"} size={"medium"} weight={"medium"}>
              <Anchor href={distributor.website} target={"_blank"}>
                {trimUrl(distributor.website)}
              </Anchor>
            </Text>
          </div>
        )}
        {distributor.email && (
          <div>
            <Text as={"span"} size={"medium"} weight={"regular"}>
              {"Email: "}
            </Text>
            <Text as={"span"} size={"medium"} weight={"medium"}>
              <Anchor href={`mailto:${distributor.email}`}>
                {distributor.email}
              </Anchor>
            </Text>
          </div>
        )}
      </div>
    </div>
  );
}
