import type { LanguageToken } from "@10x/cms/types";
import { defaultLanguage } from "@10x/site/utils/available-locales";

export default function coalesceLanguages<I extends Record<string, any>>(
  item: {
    [K in LanguageToken]: I;
  },
  targetLanguage: LanguageToken,
): I {
  return Object.entries(item[defaultLanguage]).reduce<I>(
    (accumulator, [key, fallbackValue]) => {
      return {
        ...accumulator,
        [key]: item[targetLanguage][key] || fallbackValue,
      };
    },
    {} as I,
  );
}
