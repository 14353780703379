import ConditionalLink from "../../ConditionalLink";
import Image from "../../Image/Image";
import type { Props as ContainerProps } from "./Layout/Container";
import Container from "./Layout/Container";

interface Props {
  alt: string;
  container?: ContainerProps;
  link?: string;
  src: string;
  srcSmall?: string;
}

const ImageBlock = ({ alt, container, link, src, srcSmall }: Props) => {
  return (
    <Container {...container}>
      {srcSmall ? (
        <ConditionalLink href={link}>
          <picture>
            <source media={"(max-width: 600px"} srcSet={srcSmall} />
            <Image alt={alt} src={src} />
          </picture>
        </ConditionalLink>
      ) : (
        <ConditionalLink href={link}>
          <Image alt={alt} src={src} />
        </ConditionalLink>
      )}
    </Container>
  );
};

export default ImageBlock;
