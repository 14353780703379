import type { Distributor as DistributorInterface } from "@10x/cms/model/distributor";
import { countryToLanguageMap } from "@10x/site/utils/available-locales";
import { colorGrayLightest } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { useRouter } from "next/router";
import type { HitsProvided } from "react-instantsearch-core";
import { connectHits } from "react-instantsearch-dom";

import coalesceLanguages from "../../utils/coalesceLanguages";
import DistributorAccordion from "./DistributorAccordion";
import groupDistributorsByRegion from "./group-distributors-by-region";

function DistributorsAccordions({
  hits,
}: HitsProvided<{
  en: DistributorInterface;
  ja: DistributorInterface;
  zh: DistributorInterface;
}>) {
  const { locale } = useRouter() || {};

  // Use the current locales info, and backfill with the default locale
  const localeCoalescedDistributors = hits.map((distributorWithLocales) => {
    return coalesceLanguages(
      distributorWithLocales,
      countryToLanguageMap[locale!],
    );
  });

  const groupedDistributors = groupDistributorsByRegion(
    localeCoalescedDistributors,
  );

  return (
    <div
      css={css`
        background: ${colorGrayLightest};
        display: grid;
        flex-direction: column;
        grid-gap: 2rem;
        justify-items: center;
        padding: 3.5rem 1rem 6rem;
      `}
    >
      {Object.entries(groupedDistributors).map(([region, distributors]) => {
        return (
          <DistributorAccordion
            distributors={distributors}
            key={region}
            region={region}
          />
        );
      })}
    </div>
  );
}

export default connectHits(DistributorsAccordions);
