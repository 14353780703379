import type { TextColor, TextSize } from "@10x/types";
import { css } from "@emotion/react";

import Text from "../../Text/Text";
import type { Props as ContainerProps } from "./Layout/Container";
import Container from "./Layout/Container";
import { getBulletMarker } from "./utils";

export type MarkerContent =
  | "bullet"
  | "check"
  | "circledCheck"
  | "checkBlue"
  | "warningYellow";

type Marker = {
  content: MarkerContent;
  gap?: string | number;
};

interface Props {
  color?: TextColor;
  container?: ContainerProps;
  list: string[];
  marker?: Marker | null;
  size?: TextSize;
}

const BulletedList = ({
  color = "base",
  container,
  list,
  size = "medium",
  marker,
}: Props) => {
  if (container) {
    container.as = "ul";
  }

  const bulletStyles = css`
    padding: 0;
    gap: ${marker?.gap ? marker.gap : 0};
    margin-left: 2rem;
  `;
  const markerStyles = css`
    ${marker ? "display: flex; list-style: none" : null};
    gap: ${marker?.gap ? marker.gap : 0};
    align-items: center;
    ::before {
      content: ${marker ? getBulletMarker(marker.content) : ""};
    }
  `;

  return (
    <Container
      css={css`
        margin: 0;
      `}
      {...container}
    >
      {list.map((item) => {
        return (
          <Text
            as={"li"}
            color={color}
            css={marker?.content === "bullet" ? bulletStyles : markerStyles}
            key={item}
            size={size}
          >
            {item}
          </Text>
        );
      })}
    </Container>
  );
};

export default BulletedList;
