import type { Section } from "@10x/cms/definitions/blocks/section";
import { Fragment } from "react";
import { v4 as uuidv4 } from "uuid";

import { getContent } from "../utils";
import type { Props as ContainerProps } from "./Container";
import Container from "./Container";

export interface Props {
  container: ContainerProps;
  content?: Section[];
  type?: string;
}

const Stack = ({ container, content }: Props) => {
  return (
    <Container {...container}>
      {content?.map((item) => {
        return <Fragment key={uuidv4()}>{getContent(item)}</Fragment>;
      })}
    </Container>
  );
};

export default Stack;
